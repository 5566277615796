
export default function SmallTextField(props) {
    return <input type={props.type} id={props.id} onChange={props.handleChange} onBlur={props.handleBlur} onKeyDown={props.handleKeyDown} value={props.value} className={`px-2 py-1 bg-white h-9 text-xs font-medium border focus:border-primary outline-none ${props.classes} ${!props.show ? 'hidden' : ''}`} placeholder={props.placeholder} autoComplete="off" required={props.required} />;
}


SmallTextField.defaultProps = {
    type: 'text',
    placeholder: '',
    show: true,
    id: '',
    value: '',
    required: false,
    handleBlur: () => {},
    handleKeyDown: () => {}
}