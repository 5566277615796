import PropTypes from 'prop-types'

export default function SmallButton(props) {
    const inlineStyles = {}
    if((props.gateButton && props.adjustGate) || (props.postButton && props.dragSpan)){
        inlineStyles.background = "#03658c"
        inlineStyles.color = "#fff"
    }
    return <button type={props.type} onMouseOver={props.mouseoverEvent} onClick={props.buttonEvent} id={props.id} className={`px-4 py-2 h-9 bg-secondary text-secondary-dark cursor-pointer rounded-full text-sm font-medium transition ${!props.show ? 'hidden' : ''} ${props.classes}`} style={inlineStyles} >
        {props.text}
    </button>;
}

SmallButton.defaultProps = {
    type: 'button',
    buttonEvent: ()=>{},
    mouseoverEvent: () => {},
    show: true,
    id: '',
    gateButton: false,
    adjustGate: false,
    postButton: false,
    dragSpan: false
}

SmallButton.propTypes = {
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
};