import React, { useLayoutEffect, useState } from "react"
import rough from 'roughjs/bundled/rough.esm'

import WebFont from 'webfontloader';

import { ProductService } from "./services/ProductService"

import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

import isTouchDevice from "./utils/isTouchDevice"

import PageHeading from "./components/texts/PageHeading"
import Paragraph from "./components/texts/Paragraph"
import SmallTextField from "./components/fields/SmallTextField"
import SmallButton from './components/buttons/SmallButton'
import ActionButton from "./components/buttons/RadioButton"
import PrimaryButton from "./components/buttons/PrimaryButton"
import ItemList from "./components/layout/ItemList"
import Canvas from "./components/layout/Canvas"
import GateAction from "./components/layout/GateAction"
import Modal from "./components/layout/Modal"

import post_y_icon from "./assets/layouts/post_y_icon.png"
import post_x_icon from "./assets/layouts/post_x_icon.png"
import post_z_lr_icon from "./assets/layouts/post_z_lr_icon.png"
import post_z_rl_icon from "./assets/layouts/post_z_rl_icon.png"
import post_y_light_icon from "./assets/layouts/post_y_light_icon.png"
import post_x_light_icon from "./assets/layouts/post_x_light_icon.png"
import post_z_lr_light_icon from "./assets/layouts/post_z_lr_light_icon.png"
import post_z_rl_light_icon from "./assets/layouts/post_z_rl_light_icon.png"
import gate_x from "./assets/layouts/gate_x.png"
import gate_y from "./assets/layouts/gate_y.png"
import gate_x_light from "./assets/layouts/gate_x_light.png"
import gate_y_light from "./assets/layouts/gate_y_light.png"
import gate_x_f from "./assets/layouts/gate_x_f.png"
import gate_y_f from "./assets/layouts/gate_y_f.png"
import gate_x_fa from "./assets/layouts/gate_x_f_a.png"
import gate_x_fb from "./assets/layouts/gate_x_f_b.png"
import gate_y_fa from "./assets/layouts/gate_y_f_a.png"
import gate_y_fb from "./assets/layouts/gate_y_f_b.png"

import minus from "./assets/layouts/minus.png"
import plus from "./assets/layouts/plus.png"
import minus_2 from "./assets/layouts/minus_2.png"
import plus_2 from "./assets/layouts/plus_2.png"


const generator = rough.generator()

const api = new WooCommerceRestApi({
  url: process.env.REACT_APP_WC_URL,
  consumerKey: process.env.REACT_APP_WC_CONSUMER_KEY,
  consumerSecret: process.env.REACT_APP_WC_SECRET_KEY,
  version: "wc/v3"
})

function createElement(x1, y1, x2, y2, line = '', group, stroke, color = '') {
  color = color !== '' ? "#03658c" : "rgb(164, 195, 208, 0.75)"
  const roughElement = generator.line(x1, y1, x2, y2, { strokeWidth: stroke, roughness: 0.1, fillLineDash: [5, 15], stroke: color })
  return { x1, y1, x2, y2, roughElement, line, group }
}

function createPostElement(postId, image, x1, y1, x2, y2, line = '', image_type = '', direction = '') {
  return { postId, image, x1, y1, x2, y2, line, image_type, direction }
}

function drawBoard(can, width, nRow, nCol) {
  var ctx = can.getContext("2d");
  var w = width

  w = w / nRow

  for (var i = 0; i < nRow; ++i) {

    if (w * i <= can.height - w) {
      for (var j = 0, col = nCol; j < col; ++j) {
        ctx.rect(j * w, i * w, w, w)
      }
    }
  }
  ctx.strokeStyle = "#dfdfdf";
  ctx.stroke();
}

function canvasDimension(canvasContainer) {

  const computedStyle = getComputedStyle(canvasContainer)

  let width = canvasContainer.clientWidth;
  let height = canvasContainer.clientHeight;

  width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight)
  height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)

  return { width, height }
}

const nearPoint = (x, y, x1, y1) => {
  return Math.abs(x - x1) < 1 && Math.abs(y - y1) < 1 ? "ok" : null;
};

const positionWithinElement = (x, y, baseBox, element) => {

  const { x1, x2, y1, y2 } = element

  const near = nearPoint(x, y, x1, y1)

  const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null
  return near || inside

}

const getElementAtPosition = (x, y, baseBox, elements, type) => {
  return elements
    .map(element => ({ ...element, position: positionWithinElement(x, y, baseBox, element) }))
    .find(element => element.position !== null)
}

function inLine(point, direction, canvas, cell, imageSize) {

  const length = canvas.width / cell

  const offset = 0
  const p2 = (point + length - offset) - ((point + length - offset) % length)

  imageSize = imageSize / 2

  if (point - offset < p2 - (length / 2)) {
    return p2 - (length) - imageSize
  } else {
    return p2 - imageSize
  }
}

function findLine(clientX, clientY, elements, canvas) {
  let el = []

  elements.forEach((item, index) => {

    if (item.x1 < item.x2 || item.y1 < item.y2) {
      if (clientX >= item.x1 - 16 && clientX <= item.x2 + 16 && clientY >= item.y1 - 16 && clientY <= item.y2 + 16) {
        el.push(elements[index])
      }
    } else if (item.x1 > item.x2 || item.y1 > item.y2) {
      if (clientX <= item.x1 + 16 && clientX >= item.x2 - 16 && clientY <= item.y1 + 16 && clientY >= item.y2 - 16) {
        el.push(elements[index])
      }
    }
  })

  return el
}

function findInPost(clientX, clientY, postElements, elements, postImageSize) {
  const post = postElements.filter(item => clientX >= item.x1 && clientX <= item.x1 + item.x2 && clientY >= item.y1 && clientY <= item.y1 + item.y2)
  
  if(post.length > 0){
    const line = elements.find(item => (ceil(item.x1 - postImageSize / 2) === ceil(post[0].x1) && ceil(item.y1 - postImageSize / 2) === ceil(post[0].y1)) || (ceil(item.x2 - postImageSize / 2) === ceil(post[0].x1) && ceil(item.y2 - postImageSize / 2) === ceil(post[0].y1)))
    if(line){
      return post
    }
  }
  return []
}

function getDirection(startX, startY, endX, endY, con) {
  let dir = ""
  startX = ceil(startX)
  startY = ceil(startY)
  endX = ceil(endX)
  endY = ceil(endY)
  const cx1 = ceil(con.x1)
  const cy1 = ceil(con.y1)
  const cx2 = ceil(con.x2)
  const cy2 = ceil(con.y2)
  if (startX === endX && cx1 === cx2) {
    dir = 'Y'
  } else if (startY === endY && cy1 === cy2) {
    dir = 'X'
  } else if (cx1 < cx2) {
    if ((startX === cx1 && startY < endY) || (startX === cx2 && startY > endY)) {
      dir = "LR"
    } else if ((startX === cx1 && startY > endY) || (startX === cx2 && startY < endY)) {
      dir = "RL"
    }
  } else if (cx1 > cx2) {
    if ((startX === cx2 && startY < endY) || (startX === cx1 && startY > endY)) {
      dir = "LR"
    } else if ((startX === cx2 && startY > endY) || (startX === cx1 && startY < endY)) {
      dir = "RL"
    }
  } else if (cy1 < cy2) {
    if ((startY === cy1 && startX < endX && cy1 < cy2) || (startY === cy2 && startX > endX && cy1 < cy2)) {
      dir = "LR"
    } else if ((startY === cy2 && startX < endX && cy1 < cy2) || (startY === cy1 && startX > endX && cy1 < cy2)) {
      dir = "RL"
    }
  } else if (cy1 > cy2) {
    if ((startY === cy2 && startX < endX && cy1 > cy2) || (startY === cy1 && startX > endX && cy1 > cy2)) {
      dir = "LR"
    } else if ((startY === cy1 && startX < endX && cy1 > cy2) || (startY === cy2 && startX > endX && cy1 > cy2)) {
      dir = "RL"
    }
  }

  return dir
}

function roundOff(value) {
  return Math.round(value * 100) / 100
}

function ceil(value) {
  return Math.ceil(value)
}

function getMousePos(canvas, evt) {
  var rect = canvas.getBoundingClientRect(), // abs. size of element
  scaleX = canvas.width / rect.width,    // relationship bitmap vs. element for X
  scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for Y
  let cX = evt.clientX
  let cY = evt.clientY

  // if (evt.touches || evt.changedTouches) {
  //   cX = evt.changedTouches[0] ? evt.changedTouches[0].clientX : evt.touches[0].clientX
  //   cY = evt.changedTouches[0] ? evt.changedTouches[0].clientY : evt.touches[0].clientY
  // }

  return {
    x: (cX - rect.left) * scaleX,   // scale mouse coordinates after they have
    y: (cY - rect.top) * scaleY     // been adjusted to be relative to element
  }
}

function cornerPostCounter(postElements) {
  const posts = postElements.filter(item => item.direction === 'corner')
  return posts.length
}

function straightPostCounter(postElements) {
  const posts = postElements.filter(item => item.direction === '' && item.image_type === '')
  return posts.length
}

function App() {

  const [action, setAction] = useState('Post')
  const [boardWidth, setBoardWidth] = useState(0)
  const [boardHeight, setBoardHeight] = useState(0)
  const mdCell = 16
  const smCell = 10
  const initialCell = window.innerWidth >= 768 ? mdCell : smCell
  const [cell, setCell] = useState(initialCell)
  const [prevCell, setPrevCell] = useState(initialCell)
  let baseBox = cell * 9
  const mdPostImageSize = 40
  const smPostImageSize = 30
  const initialPostImageSize = window.innerWidth >= 768 ? mdPostImageSize : smPostImageSize
  const [postImageSize, setPostImageSize] = useState(initialPostImageSize)
  const mdStroke = 22
  const smStroke = 16
  const initialStroke = window.innerWidth >= 768 ? mdStroke : smStroke
  const [stroke, setStroke] = useState(initialStroke)
  const [elements, setElements] = useState([])
  const [postElements, setPostElements] = useState([])
  const [gateElements, setGateElements] = useState([])
  const [drawing, setDrawing] = useState(false)
  const [canvas, setCanvas] = useState(null)
  const [inputLength, setInputLength] = useState(false)
  const [lastDistance, setLastDistance] = useState(null)
  const [itemList, setItemList] = useState({ "totalLength": 0, "straightPost": 0, "cornerPost": 0, "rails": [], "lastRail": 0, "gates": 0 })
  const [showCheckOut, setShowCheckOut] = useState(false)
  const [lastRailInput, setLastRailInput] = useState(0)
  const [secondClick, setSecondClick] = useState(true)
  const [tempGate, setTempGate] = useState([])
  const [canAddGate, setCanAddGate] = useState(false)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [clickedLine, setClickedLine] = useState(null)
  const [connectedLine, setConnectedLine] = useState(null)
  const [zoomTrigger, setZoomTrigger] = useState(false)
  const initialPercent = 1
  const [percent, setPercent] = useState(initialPercent)
  const [allProducts, setAllProducts] = useState([])
  const [products, setProducts] = useState([])
  const [loadInitial, setLoadInitial] = useState(false)
  const [continueProcess, setContinueProcess] = useState(true)
  const [canAdjustSpan, setCanAdjustSpan] = useState(true)
  const [showCantAdjustText, setShowCantAdjustText] = useState(false)
  const [adjustGate, setAdjustGate] = useState(false)
  const [flipGate, setFlipGate] = useState(false)
  const [dragSpan, setDragSpan] = useState(false)
  const [canDragSpan, setCanDragSpan] = useState(null)
  const [removedPosts, setRemovedPosts] = useState(0)
  const [clickedModal, setClickedModal] = useState(null)
  const [canvasImage, setCanvasImage] = useState(null)
  const [formData, setFormData] = useState({ "name": "", "email": "", "phone": "", "canvas": "", "items": [] })
  const [successFormSubmit, setSuccessFormSubmit] = useState("init")
  const mailer_url = process.env.REACT_APP_MAILER_URL
  const [ecomURL, setEcomURL] = useState('')
  const initialGateWidth = 49
  const [theGateWidth, setTheGateWidth] = useState(initialGateWidth)
  const initialGateHeight = 52
  const [theGateHeight, setTheGateHeight] = useState(initialGateHeight)
  const initialGateOffset = 32
  const [gateOffset, setGateOffset] = useState(initialGateOffset)
  const [gateOnQueue, setGateOnQueue] = useState(null)
  const [gateCurrentEvent, setGateCurrentEvent] = useState(null)
  const [grabbing, setGrabbing] = useState(false)
  const [inputString, setInputString] = useState('')
  const [resetPostsOnGrab, setResetPostsOnGrab] = useState(false)
  const [linesConnectedOnGrab, setLinesConnectedOnGrab] = useState([])
  const [clickedLineHistory, setClickedLineHistory] = useState(null)
  const [linesToBeRemoved, setLinesToBeRemoved] = useState([])
  const [dragHistory, setDragHistory] = useState(null)
  const [oppositeDirectionSpan, setOppositeDirectionSpan] = useState(false)
  const [dragPostHistory, setDragPostHistory] = useState(null)
  const [onLine, setOnLine] = useState(false)
  const [groupCounter, setGroupCounter] = useState(0)
  const [screenSize, setScreenSize] = useState(window.innerWidth >= 768 ? 'md' : 'sm')

  const handleAction = (action) => {
    setAction(action)
    setAdjustGate(false)
    setFlipGate(false)
    setGateOnQueue(null)
    setInputLength(false)
    setOnLine(false)
  }
  useLayoutEffect(() => {
    api.get('products').then((response) => {
      setProducts(response.data)
    }).catch(error => console.log(error))
  }, [])

  useLayoutEffect(() => {
    if(!isTouchDevice()){
      const checkWidth = () => {
        clearCanvas()
        if(window.innerWidth < 768){
          setCell(smCell)
          setPrevCell(smCell)
          setPostImageSize(smPostImageSize)
          setStroke(smStroke)
          setPercent(initialPercent)
          setScreenSize('sm')
        }else{
          setCell(mdCell)
          setPrevCell(mdCell)
          setPostImageSize(mdPostImageSize)
          setStroke(mdStroke)
          setPercent(initialPercent)
          setScreenSize('md')
        }
      }
      window.addEventListener('resize', checkWidth);
      checkWidth();
      return () => window.removeEventListener('resize', checkWidth);
    }
  }, [])

  useLayoutEffect(() => {
    const filters = ['straight post', 'corner post', 'gate kit', 'top and bottom rails'];
    const productsCopy = products
    let newProducts = []
    if (productsCopy.length > 0) {
      productsCopy.forEach((item, index) => {
        item['tags'].forEach((v, i) => {
          if (filters.includes(v.name)) {
            productsCopy[index].key = v.name
            newProducts.push(productsCopy[index])
          }
        })
      })
    }
  }, [products])

  useLayoutEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat:400,600,700,800']
      }
    });
   }, []);

  useLayoutEffect(() => {

    const canvasContainer = document.getElementById("canvasContainer")
    const dimension = canvasDimension(canvasContainer)
    setBoardWidth(dimension.width)
    setBoardHeight(screenSize === 'md' ? dimension.height : dimension.width)
    const canvas = document.getElementById("canvas")
    setCanvas(canvas)

    const meterView = document.getElementById("meterView")
    meterView.style.left = canvas.width + canvas.offsetLeft - (screenSize === 'md' ? 155 : 125) + "px"
    meterView.style.top = canvas.height + canvas.offsetTop - (screenSize === 'md' ? 35 : 20) + "px"

    const zoomContainer = document.getElementById("zoomContainer")
    zoomContainer.style.left = canvas.width + canvas.offsetLeft - 85 + "px"
    zoomContainer.style.top = canvas.height + canvas.offsetTop - (screenSize === 'md' ? 70 : 50) + "px"

    const context = canvas.getContext("2d")
    context.font = '14px Montserrat'
    context.fillStyle = '#54bcbe'
    context.clearRect(0, 0, canvas.width, canvas.height)

    context.beginPath()

    if (products.length === 0) {
      if(!isTouchDevice()){
        document.documentElement.style.cursor = "wait"
      }
      return
    } else {
      if (action === 'Post') {
        if(!isTouchDevice()){
          document.documentElement.style.cursor = "default"
        }
      }
      drawBoard(canvas, boardWidth, cell, cell)
    }

    const roughCanvas = rough.canvas(canvas)

    elements.forEach(({ roughElement }) => roughCanvas.draw(roughElement))
    
    gateElements.forEach((el) => context.drawImage(el.image, el.x1, el.y1, el.x2, el.y2))
    tempGate.forEach((el) => context.drawImage(el.image, el.x1, el.y1, el.x2, el.y2))
    postElements.forEach((el) => context.drawImage(el.image, el.x1, el.y1, el.x2, el.y2))
    elements.map((item, index) => {
      const totalLength = Math.sqrt(Math.pow(item.x2 - item.x1, 2) + Math.pow(item.y2 - item.y1, 2))
      const actualLength = (Math.round((totalLength / (canvas.width / cell) * 1000) * 100) / 100)
      const offset = actualLength === 1000 && item.y1 !== item.y2 ? 8 : 0
      const locationX = item.x1 !== item.x2 ? (item.x1 + item.x2) / 2 : (item.x1 + item.x2) / 2 + 24
      const locationY = item.y1 !== item.y2 ? (item.y1 + item.y2) / 2 - offset : (item.y1 + item.y2) / 2 - 24
      context.fillText(actualLength + "mm", locationX, locationY)
    })

  }, [cell, boardWidth, boardHeight, elements, elements.roughElement, postElements, gateElements, canvas, tempGate, products, screenSize])

  useLayoutEffect(() => {
    if (zoomTrigger) {
      const canvas = document.getElementById("canvas")
      const cellLength = canvas.width / cell
      const prevCellLength = canvas.width / prevCell
      const elementsCopy = [...elements]
      const postElementsCopy = [...postElements]
      const gateElementsCopy = [...gateElements]
      const newElements = []
      const newPostElements = []
      const newGateElements = []

      let percent = cellLength / prevCellLength
      const stroke1 = Math.round(stroke * percent * 100) / 100

      elementsCopy.forEach((item, index) => {
        const x1 = item.x1 * percent
        const y1 = item.y1 * percent
        const x2 = item.x2 * percent
        const y2 = item.y2 * percent
        const line = item.line
        const group = item.group
        const color = "dark"
        const el = createElement(x1, y1, x2, y2, line, group, stroke1, color)
        newElements.push(el)
      })

      postElementsCopy.forEach((item, index) => {
        const postId = item.postId
        const image = item.image
        const x1 = item.x1 * percent
        const y1 = item.y1 * percent
        const x2 = item.x2 * percent
        const y2 = item.y2 * percent
        const line = item.line
        const type = item.image_type
        const direction = item.direction
        const postEl = createPostElement(postId, image, x1, y1, x2, y2, line, type, direction)
        newPostElements.push(postEl)
      })

      gateElementsCopy.forEach((item, index) => {
        const postId = item.postId
        const image = item.image
        const x1 = item.x1 * percent
        const y1 = item.y1 * percent
        const x2 = item.x2 * percent
        const y2 = item.y2 * percent
        const line = item.line
        const image_type = item.image_type
        const postEl = createPostElement(postId, image, x1, y1, x2, y2, line, image_type)
        newGateElements.push(postEl)
      })

      setElements(newElements)
      setPostElements(newPostElements)
      setGateElements(newGateElements)

      setPostImageSize(postImageSize * percent)
      setStroke(stroke1)
      setZoomTrigger(false)
      setGateOffset(gateOffset * percent)
      setTheGateHeight(theGateHeight * percent)
      setTheGateWidth(theGateWidth * percent)
      setPercent(percent)    
    }
  }, [cell])

  useLayoutEffect(() => {
    setItemList(prevState => ({ ...prevState, gates: gateElements.length }))
  }, [gateElements])

  useLayoutEffect(() => {
    if (!inputLength) return
    const { clientX, clientY } = currentEvent

    const pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    const pointY = inLine(clientY, "y", canvas, cell, postImageSize)

    const input = document.getElementById('inputField')
    const button = document.getElementById('inputFieldBtn')

    input.style.width = screenSize === "md" ? "200px" : "170px"
    input.style.left = screenSize === "md" ? (pointX + canvas.offsetLeft + 40 + "px") : "calc(50% - 28px)"
    input.style.top = screenSize === "md" ? (pointY + canvas.offsetTop - 50 + "px") : canvas.offsetTop + 20 + "px"

    button.style.left = screenSize === "md" ? (pointX + canvas.offsetLeft + 230 + "px") : "calc(50% + 82px)"
    button.style.top = screenSize === "md" ? (pointY + canvas.offsetTop - 50 + "px") : canvas.offsetTop + 20 + "px"
    button.style.borderRadius = "0"

    if(screenSize === "sm"){
      input.style.transform = "translateX(-50%)"
      button.style.transform = "translateX(-50%)"
    }

    setSecondClick(false)

  }, [inputLength, canvas, cell, currentEvent])

  const clearCanvas = () => {
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d")

    context.clearRect(0, 0, canvas.width, canvas.height)
    setShowCheckOut(false)
    setElements([])
    setPostElements([])
    setGateElements([])
    drawBoard(canvas, boardWidth, cell, cell)
    setInputLength(false)
    setItemList({ "totalLength": 0, "straightPost": 0, "cornerPost": 0, "rails": [], "lastRail": 0, "gates": 0 })
    setTempGate([])
    setLastRailInput(0)
    setSecondClick(true)
    setTempGate([])
    setCanAddGate(false)
    setCurrentEvent(null)
    setClickedLine(null)
    setConnectedLine(null)
    setCell(initialCell)
    setPrevCell(initialCell)
    setPercent(initialPercent)
    setStroke(initialStroke)
    setPostImageSize(initialPostImageSize)
    setContinueProcess(true)
    setCanAdjustSpan(true)
    setShowCantAdjustText(false)
    setAction("Post")
    setAdjustGate(false)
    setFlipGate(false)
    setDragSpan(false)
    setCanDragSpan(null)
    setRemovedPosts(0)
    setFormData({ "name": "", "email": "", "phone": "", "canvas": "", "items": [] })
    setSuccessFormSubmit("init")
    setGateOffset(initialGateOffset)
    setGateOnQueue(null)
    setGateCurrentEvent(null)
    setTheGateWidth(initialGateWidth)
    setTheGateHeight(initialGateHeight)
    setResetPostsOnGrab(false)
    setLinesConnectedOnGrab([])
    setGrabbing(false)
    setClickedLineHistory(null)
    setLinesToBeRemoved([])
    setDragHistory(null)
    setOppositeDirectionSpan(false)
    setDragPostHistory(null)
    setOnLine(false)
    setGroupCounter(0)
  }

  const focusInputField = () => {
    const field = document.getElementById('inputField')
    field.focus()
  }

  const handleInput = (e) => {
    let value = e.target.value
    value = value.replace(/\D/g, "")
    if (value !== '') {
      setInputString(value)
      setLastDistance(value)
      setSecondClick(false)
    }
  }

  const handleBlur = (e) => {
    let value = inputString

    value = value.replace(/\D/g, "")

    if (value !== '' && value !== '0') {
      const equivalent_value = value * (canvas.width / cell / 1000)

      const start_connected = elements.filter(item => ((ceil(item.x1) === ceil(clickedLine.x1) && ceil(item.y1) === ceil(clickedLine.y1)) || (ceil(item.x2) === ceil(clickedLine.x1) && ceil(item.y2) === ceil(clickedLine.y1))) && item.line !== clickedLine.line)
      const end_connected = elements.filter(item => ((ceil(item.x2) === ceil(clickedLine.x2) && ceil(item.y2) === ceil(clickedLine.y2)) || (ceil(item.x1) === ceil(clickedLine.x2) && ceil(item.y1) === ceil(clickedLine.y2))) && item.line !== clickedLine.line)

      const postImageY = new Image()
      const postImageX = new Image()
      const postImageZLR = new Image()
      const postImageZRL = new Image()
      postImageY.src = post_y_icon
      postImageX.src = post_x_icon
      postImageZLR.src = post_z_lr_icon
      postImageZRL.src = post_z_rl_icon
      
      let elementsCopy = [...elements]
      let postElementsCopy = [...postElements]
      let railCopy = itemList.rails ? [...itemList.rails] : []
      const pushedLines = [clickedLine.line]
      
      if (end_connected.length !== 0 && start_connected.length !== 0) {
  
        const startLineX = clickedLine.x2 > clickedLine.x1 ? clickedLine.x1 : clickedLine.x2
        const endLineX = clickedLine.x2 > clickedLine.x1 ? clickedLine.x2 : clickedLine.x1
        const startLineY = clickedLine.y2 > clickedLine.y1 ? clickedLine.y1 : clickedLine.y2
        const endLineY = clickedLine.y2 > clickedLine.y1 ? clickedLine.y2 : clickedLine.y1

        const affectedLines = [clickedLine]
        let previousEl = clickedLine
        let prevPrevEL = null
        let continueLoop = true

        while (continueLoop) {

          let forEndLineX = 0
          let forEndLineY = 0

          if(!prevPrevEL){
            forEndLineX = previousEl.x2 > previousEl.x1 ? previousEl.x2 : previousEl.x1
            forEndLineY = previousEl.y2 > previousEl.y1 ? previousEl.y2 : previousEl.y1
          }else{
            forEndLineX = prevPrevEL.x1 === previousEl.x1 ? previousEl.x2 : previousEl.x1
            forEndLineY = prevPrevEL.y1 === previousEl.y1 ? previousEl.y2 : previousEl.y1
          }
          
          const foundElement = elementsCopy.find(v => ((v.x1 === forEndLineX && v.y1 === forEndLineY) || (v.x2 === forEndLineX && v.y2 === forEndLineY)) && !pushedLines.includes(v.line))
  
          if(foundElement){
            prevPrevEL = previousEl
            pushedLines.push(foundElement.line)
            previousEl = foundElement
            affectedLines.push(foundElement)
          }else{
            continueLoop = false
          }
        }

        affectedLines.forEach((item, index) => {
          const itemStartLineX = item.x2 > item.x1 ? item.x1 : item.x2
          const itemEndLineX = item.x2 > item.x1 ? item.x2 : item.x1
          const itemStartLineY = item.y2 > item.y1 ? item.y1 : item.y2
          const itemEndLineY = item.y2 > item.y1 ? item.y2 : item.y1
          let elementItem = {}
          let sign = 'add'
          let adjustedValue = 0
          const clickedLineIndex = elementsCopy.findIndex(v => v.line === item.line)

          let updatedClickedLineX2 = itemEndLineX
          let updatedClickedLineY2 = itemEndLineY

          if(item.line === clickedLine.line){

            let endPostIndex = -1
            let image = postImageY
            if(clickedLine.x1 !== clickedLine.x2){
              updatedClickedLineX2 = startLineX + equivalent_value
              elementItem = createElement(itemStartLineX, item.y1, updatedClickedLineX2, item.y2, item.line, item.group, stroke)  
              endPostIndex = postElementsCopy.findIndex(v => ceil(v.x1) === ceil(itemEndLineX - postImageSize / 2) && ceil(v.y1) === ceil(item.y1 - postImageSize / 2) && v.line === item.line) 
              if(endPostIndex > -1){
                postElementsCopy[endPostIndex].x1 = updatedClickedLineX2 - postImageSize / 2
              }

            }else{
              image = postImageX
              updatedClickedLineY2 = startLineY + equivalent_value
              elementItem = createElement(item.x1, itemStartLineY, item.x2, updatedClickedLineY2, item.line, item.group, stroke) 
              endPostIndex = postElementsCopy.findIndex(v => ceil(v.y1) === ceil(itemEndLineY - postImageSize / 2) && ceil(v.x1) === ceil(item.x1 - postImageSize / 2) && v.line === item.line)

              if(endPostIndex > -1){
                postElementsCopy[endPostIndex].y1 = updatedClickedLineY2 - postImageSize / 2
              }

            }

            const clickedLineIndex = elementsCopy.findIndex(v => v.line === clickedLine.line)
            elementsCopy[clickedLineIndex] = elementItem
            elementsCopy[clickedLineIndex]['roughElement'].options.stroke = '#03658c'
            
            const totalLength = Math.sqrt(Math.pow(updatedClickedLineX2 - item.x1, 2) + Math.pow(updatedClickedLineY2 - item.y1, 2))

            const actualLength = totalLength / (canvas.width / cell)

            let betweenPostsCtr = 0

            if (actualLength > 3 && actualLength <= 6) {
              betweenPostsCtr = 1
            } else if (actualLength > 6 && actualLength % 3 === 0) {
              betweenPostsCtr = Math.floor(actualLength / 3) - 1
            } else if (actualLength > 6 && actualLength % 3 > 0) {
              betweenPostsCtr = Math.floor(actualLength / 3)
            }

            let incrementX = betweenPostsCtr !== 0 ? Math.abs((updatedClickedLineX2 - item.x1)) / (betweenPostsCtr + 1) : 0
            let incrementY = betweenPostsCtr !== 0 ? Math.abs((updatedClickedLineY2 - item.y1)) / (betweenPostsCtr + 1) : 0

            let betweenPosts = []
            let postId = postElementsCopy.length
   
            for (let i = 0; i < betweenPostsCtr; i++) {
              postId++
              const postElement = createPostElement(postId, image, itemStartLineX + incrementX * (i + 1) - (postImageSize / 2), itemStartLineY + incrementY * (i + 1) - (postImageSize / 2), postImageSize, postImageSize, item.line, '', '')
              betweenPosts.push(postElement)
            }

            if(affectedLines.length === elementsCopy.length && (updatedClickedLineX2 !== itemEndLineX || updatedClickedLineY2 !== itemEndLineY)){
              if(endPostIndex > -1 ){
                postElementsCopy[endPostIndex].direction = ''
                postElementsCopy[endPostIndex].image = image
              }
              postId++
              const postElement = createPostElement(postId, image, updatedClickedLineX2 - (postImageSize / 2), updatedClickedLineY2 - (postImageSize / 2), postImageSize, postImageSize, item.line, '', '')
              betweenPosts.push(postElement)

              const otherLinePostIndex = postElementsCopy.findIndex(v => ceil(v.x1) === ceil(itemEndLineX - postImageSize / 2) && ceil(v.y1) === ceil(itemEndLineY - postImageSize / 2))

              if(otherLinePostIndex > -1){
                postElementsCopy[otherLinePostIndex].image = item.x1 !== item.x2 ? postImageX : postImageY
                postElementsCopy[otherLinePostIndex].direction = ''
              }else{
                const otherImage = item.x1 !== item.x2 ? postImageX : postImageY
                postId++
                const postElement = createPostElement(postId, otherImage, itemEndLineX - (postImageSize / 2), itemEndLineY - (postImageSize / 2), postImageSize, postImageSize, item.line, '', '')
                betweenPosts.push(postElement)
              }
            }


            const removePosts = postElementsCopy.filter(v => v.line !== item.line || v.direction === 'corner')
            const allPosts = [...removePosts, ...betweenPosts]
            setPostElements(allPosts)

            const railIndex = railCopy.findIndex(v => v.line === item.line)

            if(railIndex > -1){
              railCopy[railIndex].distance = actualLength * 1000
              railCopy[railIndex].count = betweenPostsCtr + 1
            }
            
            const totalStraightPosts = straightPostCounter(allPosts)
            const totalCornerPosts = cornerPostCounter(allPosts)

            setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: railCopy }))

            
          }else if(affectedLines.length !== elementsCopy.length){

            if(clickedLine.x1 !== clickedLine.x2){

              const updatedClickedLineX2 = startLineX + equivalent_value
              sign = updatedClickedLineX2 < endLineX ? 'minus' : 'add'
              adjustedValue = Math.abs(updatedClickedLineX2 - endLineX) 
              const itemUpdatedStartLineX = sign === 'minus' ? itemStartLineX - adjustedValue : itemStartLineX + adjustedValue
              const itemUpdatedEndLineX = sign === 'minus' ? itemEndLineX - adjustedValue : itemEndLineX + adjustedValue
              
              if(item.x1 !== item.x2){
                elementItem = createElement(itemUpdatedStartLineX, item.y1, itemUpdatedEndLineX, item.y2, item.line, item.group, stroke)   
              }else{
                elementItem = createElement(itemUpdatedEndLineX, item.y1, itemUpdatedEndLineX, item.y2, item.line, item.group, stroke)  
              }

            }else{
    
              const updatedClickedLineY2 = startLineY + equivalent_value
              sign = updatedClickedLineY2 < endLineY ? 'minus' : 'add'
              adjustedValue = Math.abs(updatedClickedLineY2 - endLineY) 
              const itemUpdatedStartLineY = sign === 'minus' ? itemStartLineY - adjustedValue : itemStartLineY + adjustedValue
              const itemUpdatedEndLineY = sign === 'minus' ? itemEndLineY - adjustedValue : itemEndLineY + adjustedValue
              
              if(item.x1 !== item.x2){
                elementItem = createElement(item.x1, itemUpdatedEndLineY, item.x2, itemUpdatedEndLineY, item.line, item.group, stroke)   
              }else{
                elementItem = createElement(item.x1, itemUpdatedStartLineY, item.x2, itemUpdatedEndLineY, item.line, item.group, stroke)  
              }
            }
            
            elementsCopy[clickedLineIndex] = elementItem
            elementsCopy[clickedLineIndex]['roughElement'].options.stroke = '#03658c'

            postElementsCopy.forEach((v, i) => {
              if(v.line === item.line){
                if(clickedLine.x1 !== clickedLine.x2){
                  postElementsCopy[i].x1 = sign === 'add' ? v.x1 + adjustedValue : v.x1 - adjustedValue
                }else{
                  postElementsCopy[i].y1 = sign === 'add' ? v.y1 + adjustedValue : v.y1 - adjustedValue
                }
              }
            })
          }

        })

      } else {
        elementsCopy.forEach((item, index) => {

          const b = Math.sqrt(Math.pow(equivalent_value, 2) / 2)
  
          let ax = item.x1
          let ay = item.y1
          let bx = item.x2
          let by = item.y2
  
          if (item.x1 === clickedLine.x1 && item.x2 === clickedLine.x2 && item.y1 === clickedLine.y1 && item.y2 === clickedLine.y2) {
            pushedLines.push(item.line)
            if (item.y1 === item.y2 && item.x1 < item.x2 && end_connected.length !== 0) {
              ax = item.x2 - equivalent_value
            } else if (item.x1 === item.x2 && item.y1 < item.y2 && end_connected.length !== 0) {
              ay = item.y2 - equivalent_value
            } else if (item.y1 === item.y2 && item.x1 > item.x2 && end_connected.length !== 0) {
              ax = item.x2 + equivalent_value
            } else if (item.x1 === item.x2 && item.y1 > item.y2 && end_connected.length !== 0) {
              ay = item.y2 + equivalent_value
            } else if (item.y1 === item.y2 && item.x1 > item.x2) {
              bx = item.x1 - equivalent_value
            } else if (item.x1 === item.x2 && item.y1 > item.y2) {
              by = item.y1 - equivalent_value
            } else if (item.y1 === item.y2) {
              bx = item.x1 + equivalent_value
            } else if (item.x1 === item.x2) {
              by = item.y1 + equivalent_value
            } else if (item.x1 === item.y2 || item.x2 !== item.y2) {
              bx = item.x1 + b
              by = item.y1 + b
            }
  
            const recreated = createElement(ax, ay, bx, by, item.line, item.group, stroke)
            elementsCopy[index] = recreated
            elementsCopy[index]['roughElement'].options.stroke = '#03658c'
            
            let line = postElementsCopy.filter(item => ceil(item.x1) === ceil(clickedLine.x2 - postImageSize / 2) && ceil(item.y1) === ceil(clickedLine.y2 - postImageSize / 2))
            line = line[0].line
  
            postImageY.onload = () => {
              const actualLength = equivalent_value / (canvas.width / cell)
              let betweenPostsCtr = 0
              if (actualLength > 3 && actualLength <= 6) {
                betweenPostsCtr = 1
              } else if (actualLength > 6 && actualLength % 3 === 0) {
                betweenPostsCtr = Math.floor(actualLength / 3) - 1
              } else if (actualLength > 6 && actualLength % 3 > 0) {
                betweenPostsCtr = Math.floor(actualLength / 3)
              }
              let incrementX = (bx - ax) / (betweenPostsCtr + 1)
              let incrementY = (by - ay) / (betweenPostsCtr + 1)
  
              let betweenPosts = []
  
              const postId = postElements.length
              let image = postImageX
              let imageDirection = ''
              if (ay === by) {
                image = postImageY
              } else if ((ax < bx && ay < by) || (ax > bx && ay > by)) {
                image = postImageZRL
                imageDirection = 'corner'
              } else if ((ax > bx && ay < by) || (ax < bx && ay > by)) {
                image = postImageZLR
                imageDirection = 'corner'
              }
              const startExist = elements.filter(i => ((clickedLine.x1 === i.x1 && clickedLine.y1 === i.y1) || (clickedLine.x1 === i.x2 && clickedLine.y1 === i.y2)) && clickedLine.line !== i.line)
  
              if (startExist.length === 0) {
                const firstPostElement = createPostElement(postId, image, ax - postImageSize / 2, ay - (postImageSize / 2), postImageSize, postImageSize, line, '', imageDirection)
                betweenPosts.push(firstPostElement)
  
                if (end_connected.length !== 0) {
                  let tempPost = postElementsCopy.filter(i => ceil(i.x1) === ceil(clickedLine.x2 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y2 - postImageSize / 2))
                  tempPost = tempPost[0]
                  betweenPosts.push(tempPost)
                }
              } else {
                let tempPost = postElementsCopy.filter(i => ceil(i.x1) === ceil(clickedLine.x1 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y1 - postImageSize / 2))
                tempPost = tempPost[0]
                betweenPosts.push(tempPost)
              }
  
              const loopCtr = end_connected.length !== 0 ? betweenPostsCtr - 1 : betweenPostsCtr
  
              for (let i = 0; i <= loopCtr; i++) {
                const postId = i === 0 && startExist.length === 0 ? postElements.length + i + 1 : postElements.length + i
                const postElement = createPostElement(postId, image, ax + incrementX * (i + 1) - (postImageSize / 2), ay + incrementY * (i + 1) - (postImageSize / 2), postImageSize, postImageSize, line, '', imageDirection)
                betweenPosts.push(postElement)
              }
  
              const removePosts = postElementsCopy.filter(item => item.line !== line)
  
              const allPosts = [...removePosts, ...betweenPosts]
              
              setPostElements(allPosts)

              let railCount = straightPostCounter(allPosts)
  
              railCopy = railCopy.map(i =>
                i.line === clickedLine.line
                  ? { ...i, distance: value, count: betweenPostsCtr + 1 }
                  : i
              )
              setItemList(prevState => ({ ...prevState, straightPost: railCount, rails: railCopy }))
            }
          }
        })
      }
      
      setElements(elementsCopy)

      let gatessCopy = [...gateElements]
      gatessCopy = gatessCopy.filter(item => !pushedLines.includes(item.line))
      setGateElements(gatessCopy)
    }

    setInputLength(false)
    setLastDistance(null)
    setSecondClick(true)
    setInputString('')
  }

  const handleInputFieldBlur = () => {
    const inputField = document.getElementById("inputField")
    inputField.blur()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const inputField = document.getElementById("inputField")
      inputField.blur()
    }
  }

  const handleMouseDown = (event) => {
    event.stopPropagation();
    event.preventDefault()
    if(isTouchDevice() && !event.isPrimary){
      return
    }
    if (products.length === 0 || drawing) return
    if ((inputLength && lastDistance === null) || !secondClick) {
      focusInputField()
      return
    }
    const pos = getMousePos(canvas, event);

    const gateX = new Image()
    const gateY = new Image()
    const postImageY = new Image()
    const postImageX = new Image()
    const gateXF = new Image()
    const gateXFA = new Image()
    const gateXFB = new Image()
    const gateYF = new Image()
    const gateYFA = new Image()
    const gateYFB = new Image()

    gateX.src = gate_x
    gateY.src = gate_y
    postImageY.src = post_y_icon
    postImageX.src = post_x_icon
    gateXF.src = gate_x_f
    gateXFA.src = gate_x_fa
    gateXFB.src = gate_x_fb
    gateYF.src = gate_y_f
    gateYFA.src = gate_y_fa
    gateYFB.src = gate_y_fb

    const clientX = pos.x
    const clientY = pos.y

    const pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    const pointY = inLine(clientY, "y", canvas, cell, postImageSize)
    if ((pointX - canvas.width / cell + postImageSize / 2 < 0) || (canvas.width - (pointX + postImageSize / 2) < canvas.width / cell) || (pointY - canvas.width / cell + postImageSize / 2 < 0)) return

    const line = findLine(clientX, clientY, elements, canvas)
    if (action === 'Post') {

      const inPost = findInPost(clientX, clientY, postElements, elements, postImageSize)
   
      // will not allow to make another fence that is not connected to any fence
      // if (line.length === 0 && inPost.length === 0 && elements.length > 0) {
      //   setContinueProcess(false)
      //   return
      // }
      setClickedLine(null)
      setContinueProcess(true)
      setDragSpan(false)
      setCanDragSpan(null)
      setDragHistory(null)
      setDragPostHistory(null)
      setOnLine(false)
      setOppositeDirectionSpan(false)
      setShowCantAdjustText(false)
      if (line.length > 0 && inPost.length === 0) {
        setOnLine(true)
        const fromCorner = elements.filter(item => ((line[0].x1 === item.x1 && line[0].y1 === item.y1) || (line[0].x2 === item.x2 && line[0].y2 === item.y2) || (line[0].x1 === item.x1 && line[0].y2 === item.y2) || (line[0].x2 === item.x2 && line[0].y1 === item.y1)) && line[0].line !== item.line)
        if(elements.length > 1 && fromCorner.length > 0){
          setDrawing(true)
          setResetPostsOnGrab(true)
          setClickedLineHistory(line[0])
          setClickedLine(line[0])
          setLinesToBeRemoved([])
        }         

      } else if (line.length >= 1 && inPost.length > 0) {
        const removedPostsCtr = postElements.filter(item => item.line === inPost[0].line)
        const theLine = line.find(item => (ceil(item.x1 - postImageSize / 2) === ceil(inPost[0].x1) && ceil(item.y1 - postImageSize / 2) === ceil(inPost[0].y1)) || (ceil(item.x2 - postImageSize / 2) === ceil(inPost[0].x1) && ceil(item.y2 - postImageSize / 2) === ceil(inPost[0].y1)))
        setRemovedPosts(removedPostsCtr.length)
        const data = {
          "line": theLine,
          "post": inPost[0]
        }
        
        setCanDragSpan(data)
        setDragHistory(data)
        const postsRelated = postElements.filter(item => item.line === theLine.line)
        setDragPostHistory(postsRelated)

        const sameClickedPostLineEndX = ceil(theLine.x2) === ceil(inPost[0].x1 + postImageSize / 2)
        const sameClickedPostLineEndY = ceil(theLine.y2) === ceil(inPost[0].y1 + postImageSize / 2)

        let clickedPostX = sameClickedPostLineEndX ? theLine.x1 : theLine.x2
        let clickedPostY = sameClickedPostLineEndY ? theLine.y1 : theLine.y2

        let start_connected = elements.filter(item => ceil(item.x1) === ceil(clickedPostX) && ceil(item.y1) === ceil(clickedPostY) && item.line !== theLine.line)
        let end_connected = elements.filter(item => ceil(item.x2) === ceil(clickedPostX) && ceil(item.y2) === ceil(clickedPostY) && item.line !== theLine.line)

        // if(!start_connected.length){
        //   start_connected = elements.filter(item => ceil(item.x1) === ceil(clickedPostX) && ceil(item.y1) === ceil(clickedPostY))
        // }

        // if(!end_connected.length){
        //   end_connected = elements.filter(item => ceil(item.x2) === ceil(clickedPostX) && ceil(item.y2) === ceil(clickedPostY))
        // }
        const elementsInGroup = elements.filter(item => item.group === theLine.group)
        if(elementsInGroup.length < 2){
          start_connected = elements.filter(item => ceil(item.x1) === ceil(clickedPostX) && ceil(item.y1) === ceil(clickedPostY))
          end_connected = elements.filter(item => ceil(item.x2) === ceil(clickedPostX) && ceil(item.y2) === ceil(clickedPostY))
        }  
        if(!sameClickedPostLineEndX && !sameClickedPostLineEndY){
          if (start_connected.length > 0) {
            setConnectedLine(start_connected)
          }else if(end_connected.length > 0) {
            setConnectedLine(end_connected)
          }
        }else{
          if (end_connected.length > 0) {
            setConnectedLine(end_connected)
          } else if (start_connected.length > 0) {
            setConnectedLine(start_connected)
          }
        }
        setDragSpan(true)
        setDrawing(true)
        return
      }else if(elements.length === 0 || (line.length === 0 && inPost.length === 0 && elements.length > 0)) {
        const postImageY = new Image()
        const postImageZLRL = new Image()
        postImageY.src = post_y_icon
        postImageZLRL.src = post_z_lr_light_icon
        postImageY.onload = () => {
          setContinueProcess(true)
          const postId = postElements.length
          const postElement = createPostElement(postId, postImageY, pointX, pointY, postImageSize, postImageSize)
          const positionElement = getElementAtPosition(pointX, pointY, baseBox, postElements)
          const indexLine = elements.length

          // if (!positionElement && postElements.length === 0) {
          if (!positionElement) {
            setDrawing(true)
            setPostElements(prevState => [...prevState, postElement])
            const element = createElement(pointX + postImageSize / 2, pointY + postImageSize / 2, pointX + postImageSize / 2, pointY + postImageSize / 2, indexLine, groupCounter, stroke)

            setGroupCounter(groupCounter + 1)

            setElements(prevState => [...prevState, element])
          } else if (positionElement || inPost.length !== 0) {
            if (line.length > 0 && inPost.length === 0) {
              if (line[0].x1 !== line[0].x2) {
                if (positionElement.x1 + postImageSize / 2 > line[0].x1 && positionElement.x1 + postImageSize / 2 < line[0].x2 || positionElement.x1 + postImageSize / 2 < line[0].x1 && positionElement.x1 + postImageSize / 2 > line[0].x2) {
                  setContinueProcess(false)
                  return
                }
              } else {
                if (positionElement.y1 + postImageSize / 2 > line[0].y1 && positionElement.y1 + postImageSize / 2 < line[0].y2 || positionElement.y1 + postImageSize / 2 < line[0].y1 && positionElement.y1 + postImageSize / 2 > line[0].y2) {
                  setContinueProcess(false)
                  return
                }
              }
            }

            const x = inPost.length === 0 ? pointX : inPost[0].x1
            const y = inPost.length === 0 ? pointY : inPost[0].y1

            const postElement1 = createPostElement(postId, postImageZLRL, x, y, postImageSize, postImageSize, "tmp")

            setPostElements(prevState => [...prevState, postElement1])

            const centerOfPost = postImageSize / 2

            const currentPost = postElements.filter(item => ceil(item.x1) === ceil(x))

            if (currentPost[0]) {
              setDrawing(true)
              const element = createElement(x + centerOfPost, y + centerOfPost, x + centerOfPost, y + centerOfPost, indexLine, groupCounter, stroke)
              setGroupCounter(groupCounter + 1)
              setElements(prevState => [...prevState, element])

              const start_connected = elements.filter(item => ceil(item.x1) === ceil(element.x1) && ceil(item.y1) === ceil(element.y1))
              const end_connected = elements.filter(item => ceil(item.x2) === ceil(element.x2) && ceil(item.y2) === ceil(element.y2))

              if (start_connected.length > 0) {
                setConnectedLine(start_connected)
              } else if (end_connected.length > 0) {
                setConnectedLine(end_connected)
              }
            }
          }
        }
      }else{
        setContinueProcess(false)
      }

    } else if (action === 'Gate') {
      setGateOnQueue(null)
      if (line.length > 0) {
        const existingGate = gateElements.filter(item => (clientX >= item.x1 && clientX <= item.x1 + item.x2 && (ceil(line[0].y1 - (postImageSize * .25)) === ceil(item.y1) || ceil(line[0].y1 - gateOffset - (postImageSize * .25)) === ceil(item.y1) || ceil(line[0].y1 + gateOffset - (postImageSize * .25)) === ceil(item.y1))) || (clientY >= item.y1 && clientY <= item.y1 + item.y2 && (ceil(line[0].x1 - (postImageSize * .25)) === ceil(item.x1) || ceil(line[0].x1 - gateOffset - (postImageSize * .25)) === ceil(item.x1) || ceil(line[0].x1 + gateOffset - (postImageSize * .25)) === ceil(item.x1))))

        if (existingGate.length > 0) {
          setGateOnQueue(existingGate[0])
          setGateCurrentEvent({
            "clientX": !event.touches ? event.pageX : event.touches[0].clientX, 
            "clientY": !event.touches ? event.pageY : event.touches[0].clientY
          })
          return

        }

      }

      gateX.onload = () => {
        if (tempGate.length > 0 && canAddGate) {
 
          const copyTemp = [...tempGate]

          const gateExistingInGate = gateElements.filter(item => ceil(item.x1) === ceil(copyTemp[0].x1) && ceil(item.y1) === ceil(copyTemp[0].y1))

          const gateExistingInPost = postElements.filter(item => (item.x1 + (postImageSize / 2) > copyTemp[0].x1 && item.x1 + postImageSize / 2 < copyTemp[0].x1 + copyTemp[0].x2 && ceil(item.y1 + (postImageSize * .25)) === ceil(copyTemp[0].y1)) || (item.y1 + (postImageSize / 2) > copyTemp[0].y1 && item.y1 + postImageSize / 2 < copyTemp[0].y1 + copyTemp[0].y2 && ceil(item.x1 + (postImageSize * .25)) === ceil(copyTemp[0].x1)))

          if(gateExistingInPost.length > 0 || gateExistingInGate.length > 0){
            setCanAddGate(false)
            setTempGate([])
            return
          }

          const newGates = []
          let postCount = 1
          let posts = []
          let postElement = []
          const postId = 'gate_post'
          const index = line[0].line

          if (copyTemp[0].x2 < copyTemp[0].y2) {
      
            posts = postElements.filter(item => ceil(item.y1) === ceil(copyTemp[0].y1 - (postImageSize * .25)) && (ceil(item.x1) === ceil(copyTemp[0].x1 - postImageSize / 2) || ceil(item.x1) === ceil(copyTemp[0].x1 + copyTemp[0].x2 - postImageSize / 2)))

            if (posts.length === 0) {
              postCount = 2

              const postElement1 = createPostElement(postId, postImageY, copyTemp[0].x1 - postImageSize / 2, line[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)

              const postElement2 = createPostElement(postId, postImageY, copyTemp[0].x1 + copyTemp[0].x2 - postImageSize / 2, line[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)

              newGates.push(postElement1)
              newGates.push(postElement2)


            } else if (posts.length === 1) {

              if (posts[0].x1 > copyTemp[0].x1) {
                postElement = createPostElement(postId, postImageY, copyTemp[0].x1 - postImageSize / 2, line[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)
              } else {
                postElement = createPostElement(postId, postImageY, copyTemp[0].x1 + copyTemp[0].x2 - postImageSize / 2, line[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)
              }

              newGates.push(postElement)
            }

            copyTemp[0].image = gateX
          } else {

            posts = postElements.filter(item => ceil(item.x1) === ceil(copyTemp[0].x1 - (postImageSize * .25)) && (ceil(item.y1) === ceil(copyTemp[0].y1 - postImageSize / 2) || ceil(item.y1) === ceil(copyTemp[0].y1 + copyTemp[0].y2 - postImageSize / 2)))
            if (posts.length === 0) {
              postCount = 2

              const postElement1 = createPostElement(postId, postImageX, line[0].x1 - postImageSize / 2, copyTemp[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)

              const postElement2 = createPostElement(postId, postImageX, line[0].x1 - postImageSize / 2, copyTemp[0].y1 + copyTemp[0].y2 - postImageSize / 2, postImageSize, postImageSize, index)

              newGates.push(postElement1)
              newGates.push(postElement2)
             
            } else if (posts.length === 1) {

              if (posts[0].y1 > copyTemp[0].y1) {
                postElement = createPostElement(postId, postImageX, line[0].x1 - postImageSize / 2, copyTemp[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)
              } else {
                postElement = createPostElement(postId, postImageX, line[0].x1 - postImageSize / 2, copyTemp[0].y1 + copyTemp[0].y2 - postImageSize / 2, postImageSize, postImageSize, index)
              }

              newGates.push(postElement)
            }

            copyTemp[0].image = gateY
          }    

          copyTemp[0].line = line[0].line

          setTempGate(copyTemp)

          setGateElements(prevState => [...prevState, tempGate[0]])

          setPostElements(prevState => [...prevState, ...newGates])
          setItemList(prevState => ({ ...prevState, straightPost: prevState.straightPost + postCount }))
          setTempGate([])
        }
      }
    }
  }

  const handleMouseMove = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if(isTouchDevice() && !event.isPrimary){
      return
    }
    
    let mouseDown = { x: null, y: null }

    const pos = getMousePos(canvas, event);

    const clientX = pos.x
    const clientY = pos.y

    let pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    let pointY = inLine(clientY, "y", canvas, cell, postImageSize)

    if ((pointX - canvas.width / cell + postImageSize / 2 < 0) || (canvas.width - (pointX + postImageSize / 2) < canvas.width / cell) || (pointY - canvas.width / cell + postImageSize / 2 < 0)) return
    
    if (action === 'Post') {


      if ((inputLength && lastDistance === null) || !secondClick) {
        focusInputField()
        return
      }
      if ((clientX !== mouseDown.x || clientY !== mouseDown.y) && !drawing) {
        // INLINE HOVER

      } else if (!drawing) return

      if (!drawing || elements.length === 0) return

      const postImageY = new Image()
      const postImageX = new Image()
      const postImageZLR = new Image()
      const postImageZRL = new Image()
      const postImageYLight = new Image()
      const postImageXLight = new Image()
      const postImageZLRLight = new Image()
      const postImageZRLLight = new Image()

      postImageY.src = post_y_icon
      postImageX.src = post_x_icon
      postImageZLR.src = post_z_lr_icon
      postImageZRL.src = post_z_rl_icon
      postImageYLight.src = post_y_light_icon
      postImageXLight.src = post_x_light_icon
      postImageZLRLight.src = post_z_lr_light_icon
      postImageZRLLight.src = post_z_rl_light_icon

      // GRABBING OF SPAN
      if(clickedLine){

        setGrabbing(true)
        const allLineConnected = []
        const betweenLines = []
        let removedPostsOnGrab = 0 

        let linesCopy = [...elements]
        let newConnectedLinePosts = postElements
        
        let linesConnectedInPost = elements.filter(item => ((ceil(item.x1) === ceil(clickedLine.x1) && ceil(item.y1) === ceil(clickedLine.y1)) || (ceil(item.x1) === ceil(clickedLine.x2) && ceil(item.y1) === ceil(clickedLine.y2)) || (ceil(item.x2) === ceil(clickedLine.x1) && ceil(item.y2) === ceil(clickedLine.y1)) || (ceil(item.x2) === ceil(clickedLine.x2) && ceil(item.y2) === ceil(clickedLine.y2))) && item.line !== clickedLine.line)
  
        const clickedIndex = elements.findIndex(item => ceil(item.x1) === ceil(clickedLine.x1) && ceil(item.x2) === ceil(clickedLine.x2) && ceil(item.y1) === ceil(clickedLine.y1) && ceil(item.y2) === ceil(clickedLine.y2))

        let updatedLine = {}

        allLineConnected.push(clickedLine.line)

        if(clickedLine.x1 !== clickedLine.x2){
          updatedLine = createElement(clickedLine.x1, pointY + postImageSize / 2, clickedLine.x2, pointY + postImageSize / 2, clickedLine.line, clickedLine.group, stroke)
        }else{
          updatedLine = createElement(pointX + postImageSize / 2, clickedLine.y1, pointX + postImageSize / 2, clickedLine.y2, clickedLine.line, clickedLine.group, stroke)
        }      

        const sameLinePosts = []
        const linesToBeRemoved = []        
        const linesNotConnected = []
        linesNotConnected.push(updatedLine.line)
        linesConnectedInPost.forEach(item => {
          
          const itemIndex = elements.findIndex(i => ceil(i.x1) === ceil(item.x1) && ceil(i.x2) === ceil(item.x2) && ceil(i.y1) === ceil(item.y1) && ceil(i.y2) === ceil(item.y2))
          linesNotConnected.push(item.line)
          let reverse = false

          if((ceil(item.x2) === ceil(clickedLine.x1) && ceil(item.y2) === ceil(clickedLine.y1)) || (ceil(item.x2) === ceil(clickedLine.x2) && ceil(item.y2) === ceil(clickedLine.y2))){
            reverse = true
          }
          
          let connectedLine = item

          if(ceil(item.x1) === ceil(item.x2) && ceil(item.y1) === ceil(item.y2)){
            
          }else{
            if(ceil(clickedLine.x1) !== ceil(clickedLine.x2) && ceil(item.x1) !== ceil(item.x2)){
              connectedLine = createElement(item.x1, pointY + postImageSize / 2, item.x2, pointY + postImageSize / 2, item.line, item.group, stroke)
              sameLinePosts.push(item.line)
            }else if(ceil(clickedLine.y1) !== ceil(clickedLine.y2) && ceil(item.y1) !== ceil(item.y2)){
              connectedLine = createElement(pointX + postImageSize / 2, item.y1, pointX + postImageSize / 2, item.y2, item.line, item.group, stroke)
              sameLinePosts.push(item.line)
            }else{
              if(!reverse){
                if(ceil(item.x1) !== ceil(item.x2)){
                  connectedLine = createElement(pointX + postImageSize / 2, item.y1, item.x2, item.y2, item.line, item.group, stroke)
                }else{
                  connectedLine = createElement(item.x1, pointY + postImageSize / 2, item.x2, item.y2, item.line, item.group, stroke)
                }
              }else{
                if(ceil(item.x1) !== ceil(item.x2)){
                  connectedLine = createElement(item.x1, item.y1, pointX + postImageSize / 2, item.y2, item.line, item.group, stroke)
                }else{
                  connectedLine = createElement(item.x1, item.y1, item.x2, pointY + postImageSize / 2, item.line, item.group, stroke)
                }
              }
            }
          }

          if((ceil(updatedLine.y1) !== ceil(updatedLine.y2) && ceil(connectedLine.y1) !== ceil(connectedLine.y2)) && (ceil(updatedLine.x1) === ceil(connectedLine.x1) && ceil(updatedLine.x2) === ceil(connectedLine.x2))){
         
            if(ceil(updatedLine.y1) === ceil(connectedLine.y2) || ceil(updatedLine.y2) === ceil(connectedLine.y1) || ceil(updatedLine.y1) === ceil(connectedLine.y1) || ceil(updatedLine.y2) === ceil(connectedLine.y2)){
                const coord = [connectedLine.y1, connectedLine.y2, updatedLine.y1, updatedLine.y2]
                updatedLine.y1 = Math.min( ...coord )
                updatedLine.y2 = Math.max( ...coord )

                linesToBeRemoved.push(item.line)
            }

          }else if((ceil(updatedLine.x1) !== ceil(updatedLine.x2) && ceil(connectedLine.x1) !== ceil(connectedLine.x2)) && (ceil(updatedLine.y1) === ceil(connectedLine.y1) && ceil(updatedLine.y2) === ceil(connectedLine.y2))){
            if(ceil(updatedLine.x1) === ceil(connectedLine.x2) || ceil(updatedLine.x2) === ceil(connectedLine.x1) || ceil(updatedLine.x1) === ceil(connectedLine.x1) || ceil(updatedLine.x2) === ceil(connectedLine.x2)){
              
              const coord = [connectedLine.x1, connectedLine.x2, updatedLine.x1, updatedLine.x2]
              updatedLine.x1 = Math.min( ...coord )
              updatedLine.x2 = Math.max( ...coord )
              
              linesToBeRemoved.push(item.line)
            }
          }else{
            linesCopy[itemIndex] = connectedLine
            allLineConnected.push(item.line)
          }
          allLineConnected.push(connectedLine)
          newConnectedLinePosts = newConnectedLinePosts.filter(i => {
            if(i.line === item.line){
              if((ceil(i.x1) === ceil(item.x1 - postImageSize / 2) && ceil(i.y1) === ceil(item.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(item.x2 - postImageSize / 2) && ceil(i.y1) === ceil(item.y2 - postImageSize / 2))) {
                return true
              }else{
                removedPostsOnGrab++ 
                const findBetweenLine = elements.filter(e => (ceil(i.x1) === ceil(e.x1 - postImageSize / 2) && ceil(i.y1) === ceil(e.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(e.x2 - postImageSize / 2) && ceil(i.y1) === ceil(e.y2 - postImageSize / 2)))
                if(findBetweenLine.length > 0){
                  findBetweenLine.forEach(f => {
                    betweenLines.push(f.line)
                  })
                }        
                return false
              }
            }else if(i.line === clickedLine.line){
              if((ceil(i.x1) === ceil(clickedLine.x1 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(clickedLine.x2 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y2 - postImageSize / 2))){
                return true
              }else{
                removedPostsOnGrab++
                const findBetweenLine = elements.filter(e => (ceil(i.x1) === ceil(e.x1 - postImageSize / 2) && ceil(i.y1) === ceil(e.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(e.x2 - postImageSize / 2) && ceil(i.y1) === ceil(e.y2 - postImageSize / 2)))
                if(findBetweenLine.length > 0){
                  findBetweenLine.forEach(f => {
                    betweenLines.push(f.line)
                  })
                }
                
                return false
              }
            }
            return true
          })

        })
   
        linesCopy[clickedIndex] = updatedLine
        linesCopy = linesCopy.filter(v => !linesToBeRemoved.includes(v.line))
        newConnectedLinePosts.forEach((i, j) => {
          if(((ceil(i.x1) === ceil(clickedLine.x1 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(clickedLine.x2 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y2 - postImageSize / 2))) || sameLinePosts.includes(i.line)) {
            if(clickedLine.x1 !== clickedLine.x2){
              newConnectedLinePosts[j].y1 = pointY
            }else{
              newConnectedLinePosts[j].x1 = pointX
            }
          }
        })
        
        const linesIncluded = []
        const postsExcluded = []

        linesCopy = linesCopy.filter(item => {
          if(item.x1 === item.x2 && item.y1 === item.y2){
            linesToBeRemoved.push(item.line)
            return false
          }
          linesIncluded.push(item.line)
          return true
        })

        const startConnected = linesCopy.find(v => (ceil(v.x1) === ceil(updatedLine.x1) && ceil(v.y1) === ceil(updatedLine.y1) || ceil(v.x2) === ceil(updatedLine.x1) && ceil(v.y2) === ceil(updatedLine.y1)) && v.line !== updatedLine.line)
        const endConnected = linesCopy.find(v => (ceil(v.x2) === ceil(updatedLine.x2) && ceil(v.y2) === ceil(updatedLine.y2) || ceil(v.x1) === ceil(updatedLine.x2) && ceil(v.y1) === ceil(updatedLine.y2)) && v.line !== updatedLine.line)

        const point = updatedLine.x1 === updatedLine.x2 ? updatedLine.x1 : updatedLine.y1
        const connectedToUpdatedLine = linesCopy.find(v => (((ceil(v.x1) === ceil(point) || ceil(v.x2) === ceil(point) && updatedLine.x1 === updatedLine.x2)) || ((ceil(v.y1) === ceil(point) || ceil(v.y2) === ceil(point)) && updatedLine.y1 === updatedLine.y2)) && v.line !== updatedLine.line)

        if(!startConnected && !endConnected){

          if(connectedToUpdatedLine){
            const connectedToThis = linesCopy.find(v => ((ceil(v.x1) === ceil(connectedToUpdatedLine.x1) && ceil(v.y1) === ceil(connectedToUpdatedLine.y1)) || (ceil(v.x2) === ceil(connectedToUpdatedLine.x1) && ceil(v.y2) === ceil(connectedToUpdatedLine.y1)) || (ceil(v.x1) === ceil(connectedToUpdatedLine.x2) && ceil(v.y1) === ceil(connectedToUpdatedLine.y2)) || (ceil(v.x2) === ceil(connectedToUpdatedLine.x2) && ceil(v.y2) === ceil(connectedToUpdatedLine.y2))) && v.line !== connectedToUpdatedLine.line)

            if(connectedToThis){
              if(updatedLine.x1 === updatedLine.x2){
                updatedLine.y1 = connectedToThis.y1
                updatedLine.y2 = connectedToThis.y2
              }else{
                updatedLine.x1 = connectedToThis.x1
                updatedLine.x2 = connectedToThis.x2
              }
            }else{
              if(updatedLine.x1 === updatedLine.x2){
                updatedLine.y1 = connectedToUpdatedLine.y1
              }else{
                updatedLine.x1 = connectedToUpdatedLine.x1
              }
            }
          }
        }
        
        newConnectedLinePosts = newConnectedLinePosts.filter(item => {
          if(!linesIncluded.includes(item.line)){
            return false
          }
          return true
        })
        const uniqueLines = linesCopy.filter((v,i,a)=>a.findIndex(t => (t.line === v.line)) === i)
        
        setPostElements(newConnectedLinePosts)
        setClickedLine(updatedLine)
        setElements(uniqueLines)
        setContinueProcess(false)
        setLinesConnectedOnGrab(allLineConnected)
        setLinesToBeRemoved(prevState => ([ ...prevState, ...betweenLines ]))
        if(resetPostsOnGrab){
          setItemList(prevState => ({ ...prevState, straightPost: prevState.straightPost - removedPostsOnGrab }))
          setResetPostsOnGrab(false)
        }

        return
      }
      
      let index = elements.length - 1
      let postId = postElements.length
    
      let sameDirection = false

      let { x1, y1, x2, y2, line, group } = elements[index]

      if (dragSpan) {
        if(!canDragSpan && !dragHistory){
          setDrawing(false)
          return
        }
        const dragLine = oppositeDirectionSpan ? canDragSpan.line : dragHistory.line
        const dragPost = oppositeDirectionSpan ? canDragSpan.post : dragHistory.post
   
        index = elements.findIndex(item => dragLine.line === item.line)

        x1 = dragLine.x1
        y1 = dragLine.y1
        x2 = dragLine.x2
        y2 = dragLine.y2
        line = dragLine.line
        group = dragLine.group
        
        if(dragHistory.line.x1 !== dragHistory.line.x2){
          if(dragHistory.line.y1 - postImageSize / 2 === pointY && dragHistory.line.y2 - postImageSize / 2 === pointY){
            sameDirection = true
          }
        }else{
          if(dragHistory.line.x1 - postImageSize / 2 === pointX && dragHistory.line.x2 - postImageSize / 2 === pointX){
            sameDirection = true
          }
        }
        
        const opositeX = x1 - postImageSize / 2 === dragPost.x1 ? x2 - postImageSize / 2 : x1 - postImageSize / 2
        const opositeY = y1 - postImageSize / 2 === dragPost.y1 ? y2 - postImageSize / 2 : y1 - postImageSize / 2

        const removePost = postElements.filter((item, i) => {
          if (item.x1 === opositeX && item.y1 === opositeY || !sameDirection) {
            return true
          }
          if ((item.line === line) || item.line === 'tmp') {
            return false
          }
          return true
        })

        setPostElements(removePost)

      }

      const centerOfPost = postImageSize / 2
      let postElementsCopy = [...postElements]
  
      if(dragSpan){
        if(dragHistory.line.x1 !== dragHistory.line.x2){
          if(!oppositeDirectionSpan){
            pointX = pointX
            pointY = y1 - centerOfPost
          }else{
            pointX = x1 - centerOfPost
            pointY = pointY
          }
        }else{
          if(!oppositeDirectionSpan){
            pointX = x1 - centerOfPost
            pointY = pointY
          }else{
            pointX = pointX
            pointY = y1 - centerOfPost
          }
        }
      }else{
        if (Math.abs((pointX + postImageSize / 2) - x1) < Math.abs((pointY + postImageSize / 2) - y1)) {
          pointX = x1 - centerOfPost
          pointY = pointY
        } else {
          pointX = pointX
          pointY = y1 - centerOfPost
        }
      }      

      let updatedElement = {}

      updatedElement = createElement(x1, y1, pointX + postImageSize / 2, pointY + postImageSize / 2, line, group, stroke)
      
      if (!oppositeDirectionSpan && canDragSpan && dragSpan && ceil(dragHistory.post.x1) === ceil(dragHistory.line.x1 - postImageSize / 2) && ceil(dragHistory.post.y1) === ceil(dragHistory.line.y1 - postImageSize / 2)) {
        if (Math.abs((pointX + postImageSize / 2) - x1) < Math.abs((pointY + postImageSize / 2) - y1)) {
          pointX = x2 - centerOfPost
          pointY = pointY
        } else {
          pointX = pointX
          pointY = y2 - centerOfPost
        }
        updatedElement = createElement(pointX + postImageSize / 2, pointY + postImageSize / 2, x2, y2, line, group, stroke)

      }
   
      let elementsCopy = [...elements]

      if(!sameDirection && canDragSpan && !oppositeDirectionSpan){
        let foundIndex = false
        let ctr = 0
  
        while (!foundIndex) {
          const searchIndex = elementsCopy.find(v => v.line === ctr)

          if(searchIndex){
            ctr++
          }else{
            foundIndex = true
            index = ctr
          }
        }

        updatedElement = createElement(canDragSpan.post.x1 + postImageSize / 2, canDragSpan.post.y1 + postImageSize / 2, x2, y2, index, group, stroke)
        elementsCopy.push(updatedElement)
        const history_index = elementsCopy.findIndex(item => dragHistory.line.line === item.line)
        elementsCopy[history_index] = dragHistory.line
        setElements(elementsCopy)

        setCanDragSpan({
          "post": canDragSpan.post,
          "line": updatedElement
        })

        setOppositeDirectionSpan(true)

      }else{
        if(sameDirection){
          
          setOppositeDirectionSpan(false)
          if(dragSpan && (canDragSpan.line.x1 !== dragHistory.line.x1 || canDragSpan.line.x2 !== dragHistory.line.x2 || canDragSpan.line.y1 !== dragHistory.line.y1 || canDragSpan.line.y2 !== dragHistory.line.y2)){
            elementsCopy.pop()
          }
        }
        elementsCopy[index] = updatedElement
        setElements(elementsCopy)
      }

      
      const relatedPosts = postElements.filter(item => item.line === 'tmp')

      let image_l = postImageXLight

      if (ceil(y1) === ceil(pointY + postImageSize / 2)) {
        image_l = postImageYLight
      } else if ((ceil(x1) < ceil(pointX + postImageSize / 2) && ceil(y1) < ceil(pointY + postImageSize / 2)) || (ceil(x1) > ceil(pointX + postImageSize / 2) && ceil(y1) > ceil(pointY + postImageSize / 2))) {
        image_l = postImageZRLLight
      } else if ((ceil(x1) > ceil(pointX + postImageSize / 2) && ceil(y1) < ceil(pointY + postImageSize / 2)) || (ceil(x1) < ceil(pointX + postImageSize / 2) && ceil(y1) > ceil(pointY + postImageSize / 2))) {
        image_l = postImageZLRLight
      }
      
      if (relatedPosts.length === 0) {
        const postElement = createPostElement(postId, image_l, pointX, pointY, postImageSize, postImageSize, "tmp")
        const positionElement = getElementAtPosition(pointX, pointY, baseBox, postElements)
        if (!positionElement) {
          setPostElements(prevState => [...prevState, postElement])
        }
      } else {

        const updatePost = relatedPosts[0]
        updatePost.x1 = pointX
        updatePost.y1 = pointY
        
        postElementsCopy[postElements.length - 1] = updatePost

        let image = postImageX
        if (ceil(y1) === ceil(pointY + postImageSize / 2)) {
          image = postImageY
        } else if ((ceil(x1) < ceil(pointX + postImageSize / 2) && ceil(y1) < ceil(pointY + postImageSize / 2)) || (ceil(x1) > ceil(pointX + postImageSize / 2) && ceil(y1) > ceil(pointY + postImageSize / 2))) {
          image = postImageZRL
        } else if ((ceil(x1) > ceil(pointX + postImageSize / 2) && ceil(y1) < ceil(pointY + postImageSize / 2)) || (ceil(x1) < ceil(pointX + postImageSize / 2) && ceil(y1) > ceil(pointY + postImageSize / 2))) {
          image = postImageZLR
        }

        postElementsCopy = postElementsCopy.map(item =>
          item.line === 'tmp'
            ? { ...item, image: image_l }
            : item
        )

        postElementsCopy = postElementsCopy.map(item =>
          item.line === ''
            ? { ...item, image: image }
            : item
        )

        setPostElements(postElementsCopy)
      }
      

    } else if (action === 'Gate') {

      if (clientX !== mouseDown.x || clientY !== mouseDown.y) {

        const line = findLine(clientX, clientY, elements, canvas)

        const gateWidth = theGateWidth
        const gateHeight = theGateHeight

        if (line.length > 0) {

          if (line[0].x1 !== line[0].x2 && line[0].y1 !== line[0].y2) {
            setCanAddGate(false)
            return
          }

          const existingGate = gateElements.filter(item => (clientX >= item.x1 && clientX <= item.x1 + item.x2 && (ceil(line[0].y1 - (postImageSize * .25)) === ceil(item.y1) || ceil(line[0].y1 - gateOffset - (postImageSize * .25)) === ceil(item.y1) || ceil(line[0].y1 + gateOffset - (postImageSize * .25)) === ceil(item.y1))) || (clientY >= item.y1 && clientY <= item.y1 + item.y2 && (ceil(line[0].x1 - (postImageSize * .25)) === ceil(item.x1) || ceil(line[0].x1 - gateOffset - (postImageSize * .25)) === ceil(item.x1) || ceil(line[0].x1 + gateOffset - (postImageSize * .25)) === ceil(item.x1))))

          if (existingGate.length > 0) {
            if(!isTouchDevice()){
              document.documentElement.style.cursor = "default"
            }
            setTempGate([])
            return
          }
          if(!isTouchDevice()){
            document.documentElement.style.cursor = "pointer"
          }
          setCanAddGate(true)
          const gateX_light = new Image()
          const gateY_light = new Image()

          gateX_light.src = gate_x_light
          gateY_light.src = gate_y_light

          const postId = gateElements.length

          const cursorX = clientX
          const cursorY = clientY

          let updatedX1 = cursorX
          let updatedY1 = cursorY

          let showGateSnap = true

          gateX_light.onload = () => {
            if (tempGate.length === 0) {

              let gateElement = {}

              if (line[0].x1 !== line[0].x2) {
                gateElement = createPostElement(postId, gateX_light, line[0].x1, line[0].y1 - (postImageSize * .25), gateWidth, gateHeight)
              } else {
                gateElement = createPostElement(postId, gateY_light, line[0].x1 - (postImageSize * .25), line[0].y1, gateHeight, gateWidth)
              }
              setTempGate([gateElement])
            } else {
              let updateGateElement = {}

              if (line[0].x1 !== line[0].x2) {
                if (line[0].x1 < line[0].x2) {
                  if (line[0].x2 - gateWidth < cursorX) {
                    updatedX1 = line[0].x2 - gateWidth
                  } else if (line[0].x1 < cursorX) {
                    updatedX1 = cursorX
                  } else {
                    updatedX1 = line[0].x1
                  }
                } else {
                  if (line[0].x1 - gateWidth < cursorX) {
                    updatedX1 = line[0].x1 - gateWidth
                  } else if (line[0].x2 < cursorX) {
                    updatedX1 = cursorX
                  } else {
                    updatedX1 = line[0].x2
                  }
                }

                let existingGateInPosts = postElements.filter(item => updatedX1 + item.x2 + (10 * percent) >= item.x1 && updatedX1 <= item.x1 + item.x2 && ceil(line[0].y1 - (postImageSize / 2)) === ceil(item.y1))
                
                if (existingGateInPosts.length > 0) {

                  if (line[0].x1 !== line[0].x2) {
                    if (line[0].x1 < line[0].x2) {
                      if (cursorX > existingGateInPosts[0].x1 - gateWidth && cursorX < existingGateInPosts[0].x1 + existingGateInPosts[0].x2 && cursorX > line[0].x1 + postImageSize && cursorX < line[0].x2 - postImageSize) {
                        if (cursorX <= existingGateInPosts[0].x1 + existingGateInPosts[0].x2
                          / 2) {
                          updatedX1 = existingGateInPosts[0].x1 - gateWidth + (postImageSize / 2)
                        } else {
                          updatedX1 = existingGateInPosts[0].x1 + existingGateInPosts[0].x2 - (postImageSize / 2)
                        }
                      } else if (line[0].x2 - line[0].x1 - gateWidth < cursorX && cursorX >= line[0].x2 - line[0].x1) {
                        updatedX1 = line[0].x1 + line[0].x2 - line[0].x1 - gateWidth
                      } else {
                        updatedX1 = line[0].x1
                      }
                    } else {
                      if (cursorX > existingGateInPosts[0].x1 - gateWidth && cursorX < existingGateInPosts[0].x1 + existingGateInPosts[0].x2 && cursorX > line[0].x2 + postImageSize && cursorX < line[0].x1 - postImageSize) {
                        if (cursorX <= existingGateInPosts[0].x1 + existingGateInPosts[0].x2 / 2) {
                          updatedX1 = existingGateInPosts[0].x1 - gateWidth + (postImageSize / 2)
                        } else {
                          updatedX1 = existingGateInPosts[0].x1 + existingGateInPosts[0].x2 - (postImageSize / 2)
                        }
                      } else if (line[0].x1 - line[0].x2 - gateWidth < cursorX && cursorX >= line[0].x1 - line[0].x2) {
                        updatedX1 = line[0].x2 + line[0].x1 - line[0].x2 - gateWidth
                      } else {
                        updatedX1 = line[0].x2
                      }
                    }
                  }
                }

                if (showGateSnap) {
                  updateGateElement = createPostElement(postId, gateX_light, updatedX1, line[0].y1 - (postImageSize * .25), gateWidth, gateHeight)
                }


              } else {
                if (line[0].y1 < line[0].y2) {
                  if (line[0].y2 - gateWidth < cursorY) {
                    updatedY1 = line[0].y2 - gateWidth
                  } else if (line[0].y1 < cursorY) {
                    updatedY1 = cursorY
                  } else {
                    updatedY1 = line[0].y1
                  }
                } else {
                  if (line[0].y1 - gateWidth < cursorY) {
                    updatedY1 = line[0].y1 - gateWidth
                  } else if (line[0].y2 < clientY) {
                    updatedY1 = cursorY
                  } else {
                    updatedY1 = line[0].y2
                  }
                }

                let existingGateInPosts = postElements.filter(item => updatedY1 + item.y2 + (10 * percent) >= item.y1 && updatedY1 <= item.y1 + item.y2 && ceil(line[0].x1 - (postImageSize / 2)) === ceil(item.x1))
                if (existingGateInPosts.length > 0) {

                  if (line[0].y1 !== line[0].y2) {
                    if (line[0].y1 < line[0].y2) {
                      if (cursorY > existingGateInPosts[0].y1 - gateWidth && cursorY < existingGateInPosts[0].y1 + existingGateInPosts[0].y2 && cursorY > line[0].y1 + postImageSize && cursorY < line[0].y2 - postImageSize) {
                        if (cursorY <= existingGateInPosts[0].y1 + existingGateInPosts[0].y2
                          / 2) {
                          updatedY1 = existingGateInPosts[0].y1 - gateWidth + (postImageSize / 2)
                        } else {
                          updatedY1 = existingGateInPosts[0].y1 + existingGateInPosts[0].y2 - (postImageSize / 2)
                        }
                      } else if (line[0].y2 - line[0].y1 - gateWidth < cursorY && cursorY >= line[0].y2 - line[0].y1) {
                        updatedY1 = line[0].y1 + line[0].y2 - line[0].y1 - gateWidth
                      } else {
                        updatedY1 = line[0].y1
                      }
                    } else {
                      if (cursorY > existingGateInPosts[0].y1 - gateWidth && cursorY < existingGateInPosts[0].y1 + existingGateInPosts[0].y2 && cursorY > line[0].y2 + postImageSize && cursorY < line[0].y1 - postImageSize) {
                        if (cursorY <= existingGateInPosts[0].y1 + existingGateInPosts[0].y2 / 2) {
                          updatedY1 = existingGateInPosts[0].y1 - gateWidth + (postImageSize / 2)
                        } else {
                          updatedY1 = existingGateInPosts[0].y1 + existingGateInPosts[0].y2 - (postImageSize / 2)
                        }
                      } else if (line[0].y1 - line[0].y2 - gateWidth < cursorY && cursorY >= line[0].y1 - line[0].y2) {
                        updatedY1 = line[0].y2 + line[0].y1 - line[0].y2 - gateWidth
                      } else {
                        updatedY1 = line[0].y2
                      }
                    }
                  }
                }

                if (showGateSnap) {
                  updateGateElement = createPostElement(postId, gateY_light, roundOff(line[0].x1 - (postImageSize * .25)), updatedY1, gateHeight, gateWidth)
                }

              }

              if (showGateSnap) {
                const copyTemp = [...tempGate]
                copyTemp[0] = updateGateElement
                setTempGate(copyTemp)
              }
            }
          }
        } else {
          if(!isTouchDevice()){
            document.documentElement.style.cursor = "default"
          }
          setTempGate([])
        }

      }
    }
  }

  const handleMouseUp = (event) => {
    event.stopPropagation();
    event.preventDefault()

    if(isTouchDevice() && !event.isPrimary){
      return
    }
    setDrawing(false)
    const postImageY = new Image()
    const postImageX = new Image()
    const postImageZLR = new Image()
    const postImageZRL = new Image()
    postImageY.src = post_y_icon
    postImageX.src = post_x_icon
    postImageY.src = post_y_icon
    postImageX.src = post_x_icon
    postImageZLR.src = post_z_lr_icon
    postImageZRL.src = post_z_rl_icon

    if(grabbing){

      setGrabbing(false)

      let moved = true

      if(clickedLine.x1 !== clickedLine.x2 && clickedLineHistory){
        if(clickedLineHistory.y1 === clickedLine.y1 && clickedLineHistory.y2 === clickedLine.y2){
          moved = false
        }
      }else{
        if(clickedLineHistory.x1 === clickedLine.x1 && clickedLineHistory.x2 === clickedLine.x2 ){
          moved = false
        }
      }

      const lines = linesConnectedOnGrab
      let elementsCopyA = [...elements]
      let postsCopyA = [...postElements]
      let gatessCopyA = [...gateElements]
      

      const betweenPosts = []
      let totalBetweenPostsOnGrab = 0

      let railCopy = itemList.rails.length ? [...itemList.rails] : [] 
      const lineLines = []
      const movedLines = []
      
      const removeNotCorner = []
      postsCopyA.forEach((item, index) => {
        if(item.line === clickedLineHistory.line){
          const checkIfCorner = elementsCopyA.find(v => (ceil(v.x1 - postImageSize / 2) === ceil(item.x1) && ceil(v.y1 - postImageSize / 2) === ceil(item.y1)) || (ceil(v.x2 - postImageSize / 2) === ceil(item.x1) && ceil(v.y2 - postImageSize / 2) === ceil(item.y1)))
          if(!checkIfCorner){
            removeNotCorner.push(index)
          }
        }
      })

      postsCopyA = postsCopyA.filter((v, i) => !removeNotCorner.includes(i))

      elementsCopyA.forEach((item, index) => {
        lineLines.push(item.line)
        if (lines.some(v => item.line === v)) {
          movedLines.push(item.line)
          elementsCopyA[index]['roughElement'].options.stroke = '#03658c'
          const totalLength = Math.sqrt(Math.pow(Math.abs(item.x2 - item.x1), 2) + Math.pow(Math.abs(item.y2 - item.y1), 2))
          const actualLength = totalLength / (canvas.width / cell)
          let betweenPostsCtr = 0
          if (actualLength > 3 && actualLength <= 6) {
            betweenPostsCtr = 1
          } else if (actualLength > 6 && actualLength % 3 === 0) {
            betweenPostsCtr = Math.floor(actualLength / 3) - 1
          } else if (actualLength > 6 && actualLength % 3 > 0) {
            betweenPostsCtr = Math.floor(actualLength / 3)
          }
          let incrementX = betweenPostsCtr !== 0 ? (Math.abs(item.x2 - item.x1)) / (betweenPostsCtr + 1) : 0
          let incrementY = betweenPostsCtr !== 0 ? (Math.abs(item.y2 - item.y1)) / (betweenPostsCtr + 1) : 0

          const image = item.x1 !== item.x2 ? postImageY : postImageX
          const postId = postElements.length

          const startX = item.x1 > item.x2 ? item.x2 : item.x1
          const startY = item.y1 > item.y2 ? item.y2 : item.y1

          for (let i = 0; i < betweenPostsCtr; i++) {
            const postElement = createPostElement(postId + i, image, startX + incrementX * (i + 1) - (postImageSize / 2), startY + incrementY * (i + 1) - (postImageSize / 2), postImageSize, postImageSize, item.line)
            betweenPosts.push(postElement)
            totalBetweenPostsOnGrab++
          }

          if(item.line === clickedLineHistory.line){
            
            const startConnected = elementsCopyA.filter(v => (ceil(v.x1) === ceil(item.x1) && ceil(v.y1) === ceil(item.y1) || ceil(v.x2) === ceil(item.x1) && ceil(v.y2) === ceil(item.y1)) && v.line !== item.line)
            const endConnected = elementsCopyA.filter(v => (ceil(v.x2) === ceil(item.x2) && ceil(v.y2) === ceil(item.y2) || ceil(v.x1) === ceil(item.x2) && ceil(v.y1) === ceil(item.y2)) && v.line !== item.line)

            const existingPostStart = postsCopyA.filter(v => ceil(v.x1) === ceil(item.x1 - postImageSize / 2) && ceil(v.y1) === ceil(item.y1 - postImageSize / 2))
            const existingPostEnd = postsCopyA.filter(v => ceil(v.x1) === ceil(item.x2 - postImageSize / 2) && ceil(v.y1) === ceil(item.y2 - postImageSize / 2))

            if(startConnected.length === 0){
              if(existingPostStart.length === 0){
                const postElement = createPostElement(totalBetweenPostsOnGrab + 1, image, item.x1 - postImageSize / 2, item.y1 - postImageSize / 2, postImageSize, postImageSize, item.line)
                betweenPosts.push(postElement)
                totalBetweenPostsOnGrab++

              }else{
                
                const postIndex = postsCopyA.findIndex(v => ceil(v.x1) === ceil(item.x1 - postImageSize / 2) && ceil(v.y1) === ceil(item.y1 - postImageSize / 2))
                if(postIndex > -1){
                  postsCopyA[postIndex].image = image
                  postsCopyA[postIndex].direction = ''
                }
              }
            }else{
              if(existingPostStart.length === 0){
                const dir = getDirection(item.x1, item.y1, item.x2, item.y2, startConnected[0])
     
                let cImage = postImageY
                if (dir === 'Y') {
                  cImage = postImageX
                } else if (dir === "RL") {
                  cImage = postImageZLR
                } else if (dir === "LR") {
                  cImage = postImageZRL
                }
                const postElement = createPostElement(totalBetweenPostsOnGrab + 1, cImage, item.x1 - postImageSize / 2, item.y1 - postImageSize / 2, postImageSize, postImageSize, item.line, '', 'corner')
                betweenPosts.push(postElement)
                totalBetweenPostsOnGrab++
              }
              
            }

            if(endConnected.length === 0){
              if(existingPostEnd.length === 0){
                const postElement = createPostElement(totalBetweenPostsOnGrab + 1, image, item.x2 - postImageSize / 2, item.y2 - postImageSize / 2, postImageSize, postImageSize, item.line)
                betweenPosts.push(postElement)
                totalBetweenPostsOnGrab++
              }else{
                const postIndex = postsCopyA.findIndex(v => ceil(v.x1) === ceil(item.x2 - postImageSize / 2) && ceil(v.y1) === ceil(item.y2 - postImageSize / 2))
                if(postIndex > -1){
                  postsCopyA[postIndex].image = image
                  postsCopyA[postIndex].direction = ''
                }
              }
            }else{
              if(existingPostEnd.length === 0){
                const dir = getDirection(item.x1, item.y1, item.x2, item.y2, endConnected[0])
    
                let cImage = postImageY
                if (dir === 'Y') {
                  cImage = postImageX
                } else if (dir === "RL") {
                  cImage = postImageZLR
                } else if (dir === "LR") {
                  cImage = postImageZRL
                }

                const postElement = createPostElement(totalBetweenPostsOnGrab + 1, cImage, item.x2 - postImageSize / 2, item.y2 - postImageSize / 2, postImageSize, postImageSize, item.line, '', 'corner')
                betweenPosts.push(postElement)
                totalBetweenPostsOnGrab++
              }
              
            }
          }

          railCopy.forEach((v, i) => {
            if(v.line === item.line){
              railCopy[i].distance = actualLength * 1000
              railCopy[i].count = betweenPostsCtr + 1
            }
          })

        }
      })    
      
      let allPostsA = [...postsCopyA, ...betweenPosts]
      
      if(moved && linesToBeRemoved.length > 0){
        elementsCopyA = elementsCopyA.filter(item => !linesToBeRemoved.includes(item.line))

        allPostsA = allPostsA.filter(item => !linesToBeRemoved.includes(item.line))
        railCopy = railCopy.filter(item => !linesToBeRemoved.includes(item.line))
        
      }

      if(moved){
        gatessCopyA = gatessCopyA.filter(item => !movedLines.includes(item.line) && lineLines.includes(item.line))
        allPostsA = allPostsA.filter((v,i,a)=>a.findIndex(t => (t.x1 === v.x1 && t.y1 === v.y1)) === i)
        railCopy = railCopy.filter(item => lineLines.includes(item.line))

        allPostsA.forEach((e, i) => {
          if(e.direction === ''){
            const refixPost = elementsCopyA.filter(v => (ceil(v.x1 - postImageSize / 2) === ceil(e.x1) && ceil(v.y1 - postImageSize / 2) === ceil(e.y1)) || (ceil(v.x2 - postImageSize / 2) === ceil(e.x1) && ceil(v.y2 - postImageSize / 2) === ceil(e.y1)))
       
            if(refixPost.length > 1){
              const dir = getDirection(refixPost[0].x1, refixPost[0].y1, refixPost[0].x2, refixPost[0].y2, refixPost[1])
              let cImage = postImageY
              let cImageDirection = ''
              if (dir === 'Y') {
                cImage = postImageX
              } else if (dir === "LR") {
                cImage = postImageZLR
                cImageDirection = 'corner'
              } else if (dir === "RL") {
                cImage = postImageZRL
                cImageDirection = 'corner'
              }

              allPostsA[i].image = cImage
              allPostsA[i].direction = cImageDirection
            }
          }
        })        
      }
      

      const totalCornerPosts = cornerPostCounter(allPostsA)
      const totalStraightPosts = straightPostCounter(allPostsA)

      setElements(elementsCopyA)
      setPostElements(allPostsA)
      setGateElements(gatessCopyA)
      setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: railCopy }))
      setLinesConnectedOnGrab([])
      return
    }else if(!grabbing && onLine){
      return
    }

    if ((inputLength && lastDistance === null) || !secondClick || elements.length === 0 || !continueProcess) {
      focusInputField()
      setSecondClick(true)
      return
    }
    setClickedLine(null)

    const pos = getMousePos(canvas, event);

    const clientX = pos.x
    const clientY = pos.y
    let pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    let pointY = inLine(clientY, "y", canvas, cell, postImageSize)

    const index = !oppositeDirectionSpan && canDragSpan && dragSpan ? elements.findIndex(item => item.line === dragHistory.line.line) : elements.length - 1

    const { x1, x2, y1, y2, line, group } = elements[index]
    const centerOfPost = postImageSize / 2

    if (Math.abs((pointX + postImageSize / 2) - x1) < Math.abs((pointY + postImageSize / 2) - y1)) {
      pointX = x1 - centerOfPost
      pointY = pointY
    } else {
      pointX = pointX
      pointY = y1 - centerOfPost
    }
    
    if (action === 'Post') {
      if((ceil(pointX) !== ceil(x1 - postImageSize / 2) && ceil(pointX) !== ceil(x2 - postImageSize / 2)) || (ceil(pointY) !== ceil(y1 - postImageSize / 2) && ceil(pointY) !== ceil(y2 - postImageSize / 2))){
        return
      }
      let positionElement = getElementAtPosition(pointX, pointY, baseBox, postElements)
      if (!positionElement && postElements.length > 0) {
        
        const lastPost = postElements[postElements.length - 1]
        if (lastPost.line === 'tmp') {
          positionElement = lastPost
        }
      }

      if (positionElement) {

        if (elements.length > 0) {

          setShowCheckOut(true)

          const elementsCopy = [...elements]
       
          const existingElement = elements.filter(item => item.x1 === x1 && item.y1 === y1 && item.x2 === x2 && item.y2 === y2 && item.line !== line)
    
          const zeroDistance = Math.abs(x2 - x1) === 0 && Math.abs(y2 - y1) === 0
          if (zeroDistance || existingElement.length > 0) {

            let removeLast = elementsCopy
            let removeLastPost = [...postElements]
      
            removeLast = removeLast.filter(item => item.line !== elements[index].line)
          
            removeLastPost = removeLastPost.filter(item => item.line !== elements[index].line && item.line !== '' && item.line !== 'tmp')
         
            if(zeroDistance && removeLast.length > 0 && removeLastPost.length > 0){
         
              const existingPostIndex = removeLastPost.findIndex(item => item.x1 === elements[index].x1 - postImageSize / 2 && item.y1 === elements[index].y1 - postImageSize / 2)
              const existineLine = removeLast.find(item => (item.x1 === elements[index].x1 && item.y1 === elements[index].y1 || item.x2 === elements[index].x1 && item.y2 === elements[index].y1))
              let image = ''
              if(existineLine){
                image = existineLine.x1 !== existineLine.x2 ? postImageY : postImageX
              }
              if(existingPostIndex > -1){
                
                if(existineLine){
                  removeLastPost[existingPostIndex].image = image
                  removeLastPost[existingPostIndex].direction = ''
                  removeLastPost[existingPostIndex].line = existineLine.line
                }
              }else{
                
                if(existineLine){
                  const postElement = createPostElement(removeLastPost.length + 1, image, elements[index].x1 - postImageSize / 2, elements[index].y1 - postImageSize / 2, postImageSize, postImageSize, existineLine.line)
                  removeLastPost.push(postElement)
                }
              }
            }

            setElements(removeLast)
            setPostElements(removeLastPost)

            let railCopy = itemList.rails.length ? [...itemList.rails] : []

            // let rails = railCopy.find(item => item.line !== elements[index].line)
            // rails = rails ? rails : []
            const totalCornerPosts = cornerPostCounter(removeLastPost)
            const totalStraightPosts = straightPostCounter(removeLastPost)
            setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: railCopy }))
            setConnectedLine(null)
            return
          }

          const lastEl = elementsCopy[elementsCopy.length - 1]

          const lastElLine = findLine(lastEl.x1, lastEl.y1, elements, canvas)

          if (lastElLine.length >= 2) {
            
            let theLine = []
            let removeLastEl = false

            if (lastEl.x1 !== lastEl.x2) {
              theLine = lastElLine.filter(item => item.x2 !== lastEl.x2 && item.y1 === item.y2)
              if (theLine.length !== 0) {
                if (theLine[0].x1 < theLine[0].x2 && lastEl.x2 < theLine[0].x2 && lastEl.x1 !== theLine[0].x1) {
                  removeLastEl = true
                } else if (theLine[0].x1 < theLine[0].x2 && lastEl.x2 > theLine[0].x1 && lastEl.x1 === theLine[0].x1) {
                  removeLastEl = true
                } else if (theLine[0].x1 > theLine[0].x2 && lastEl.x2 > theLine[0].x2 && lastEl.x1 === theLine[0].x2) {
                  removeLastEl = true
                } else if (theLine[0].x1 > theLine[0].x2 && lastEl.x2 < theLine[0].x1 && lastEl.x1 !== theLine[0].x2) {
                  removeLastEl = true
                }
              }
            } else {
              theLine = lastElLine.filter(item => item.y2 !== lastEl.y2 && item.x1 === item.x2)
              if (theLine.length !== 0) {
                if (theLine[0].y1 < theLine[0].y2 && lastEl.y2 < theLine[0].y2 && lastEl.y1 !== theLine[0].y1) {
                  removeLastEl = true
                } else if (theLine[0].y1 < theLine[0].y2 && lastEl.y2 > theLine[0].y1 && lastEl.y1 === theLine[0].y1) {
                  removeLastEl = true
                } else if (theLine[0].y1 > theLine[0].y2 && lastEl.y2 > theLine[0].y2 && lastEl.y1 === theLine[0].y2) {
                  removeLastEl = true
                } else if (theLine[0].y1 > theLine[0].y2 && lastEl.y2 < theLine[0].y1 && lastEl.y1 !== theLine[0].y2) {
                  removeLastEl = true
                }
              }
            }

            if (removeLastEl) {

              const removeLast = elementsCopy
              const removeLastPost = [...postElements]

              removeLast.pop()
              removeLastPost.pop()

              setElements(removeLast)
              setPostElements(removeLastPost)

              return
            }
          }

          setLastRailInput(lastRailInput + 1)
          
          const reverse = !oppositeDirectionSpan && canDragSpan && dragSpan && canDragSpan.post.x1 === canDragSpan.line.x1 - postImageSize / 2 && canDragSpan.post.y1 === canDragSpan.line.y1 - postImageSize / 2 ? true : false

          if (reverse) {
            if(x1 !== x2){
              pointX = x1 - centerOfPost
              pointY = pointY
            }else{
              pointX = pointX
              pointY = y1 - centerOfPost
            }
          } else {
            if(x1 !== x2){
              pointX = x2 - centerOfPost
              pointY = pointY
            }else{
              pointX = pointX
              pointY = y2 - centerOfPost
            }
          }

          let postElementsCopy = [...postElements]

          let startX = !reverse ? x1 : pointX + centerOfPost
          let endX = !reverse ? positionElement.x1 + centerOfPost : x2
          let startY = !reverse ? y1 : pointY + centerOfPost
          let endY = !reverse ? positionElement.y1 + centerOfPost : y2

          let removePostLine = 'x'
          elementsCopy.forEach(v => {
            if(v.line !== line){
              if((startX === v.x1 && startY === v.y1) || (endX === v.x1 && endY === v.y1) || (startX === v.x2 && startY === v.y2) || (endX === v.x2 && endY === v.y2)){
                let changeLine = false
                if(startX !== endX && v.x1 !== v.x2){
                  const coord = [startX, endX, v.x1, v.x2]
                  startX = Math.min( ...coord )
                  endX = Math.max( ...coord )
                  changeLine = true
                  removePostLine = v.line
                }else if(startY !== endY && v.y1 !== v.y2){
                  const coord = [startY, endY, v.y1, v.y2]
                  startY = Math.min( ...coord )
                  endY = Math.max( ...coord )
                  changeLine = true
                  removePostLine = v.line
                }

                if(changeLine){
               
                  postElementsCopy = postElementsCopy.map(val =>
                    val.line === removePostLine && val.direction === 'corner'
                      ? { ...val, line: line }
                      : val
                  )
                }
              }
            }            
          })

          const updatedElement = createElement(startX, startY, endX, endY, line, group, stroke)

          elementsCopy[index] = updatedElement
          elementsCopy[index]['roughElement'].options.stroke = '#03658c'
          const finalElements = elementsCopy.filter(v => v.line !== removePostLine)
          setElements(finalElements)
 
          // if(oppositeDirectionSpan && canDragSpan && dragSpan && ((lastEl.x1 !== startX || lastEl.x2 !== endX) || (lastEl.y1 !== startY || lastEl.y2 !== endY))){
    
          //   const reGate = gateElements.filter(item => item.line !== lastEl.line)
            
          //   setGateElements(reGate)

          // }

          if(canDragSpan && dragSpan){
            const reGate = gateElements.filter(item => item.line !== lastEl.line)
            setGateElements(reGate)
          }
          
          if(oppositeDirectionSpan && canDragSpan && dragSpan){
            postElementsCopy = [...postElementsCopy, ...dragPostHistory]
          }
          postImageY.onload = () => {
            if (connectedLine && elements.length >= 2) {
              const con = oppositeDirectionSpan && dragSpan ? dragHistory.line : connectedLine[0]
              const dir = getDirection(startX, startY, endX, endY, con)
              let cImage = postImageY
              let cImageDirection = ''
              if (dir === 'Y') {
                cImage = postImageX
              } else if (dir === "LR") {
                cImage = postImageZLR
                cImageDirection = 'corner'
              } else if (dir === "RL") {
                cImage = postImageZRL
                cImageDirection = 'corner'
              }
              
              postElementsCopy.forEach((item, index) => {
                
                if (ceil(item.x1) === ceil(startX - centerOfPost) && ceil(item.y1) === ceil(startY - centerOfPost) && item.line !== 'tmp') {
                  postElementsCopy[index].image = cImage
                  postElementsCopy[index].direction = cImageDirection
                  // if (connectedLine.length > 0 && ((startY === endY && connectedLine[0].y1 !== connectedLine[0].y2) || (startX === endX && connectedLine[0].x1 !== connectedLine[0].x2))) {
        
                  // }
                }
                
                if (ceil(item.x1) === ceil(endX - centerOfPost) && ceil(item.y1) === ceil(endY - centerOfPost) && item.line !== 'tmp') {
                  postElementsCopy[index].image = dir === "LR" ? postImageZRL : postImageZLR
                  postElementsCopy[index].direction = cImageDirection
                }
              })
            }

            const relatedPosts = postElements.filter(item => item.line === 'tmp')

            if (relatedPosts.length > 0) {

              const updatePost = relatedPosts[0]

              updatePost.image = postImageY

              postElementsCopy[postElements.length - 1] = updatePost

              const totalLength = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2))
              const actualLength = totalLength / (canvas.width / cell)
              let betweenPostsCtr = 0
              if (actualLength > 3 && actualLength <= 6) {
                betweenPostsCtr = 1
              } else if (actualLength > 6 && actualLength % 3 === 0) {
                betweenPostsCtr = Math.floor(actualLength / 3) - 1
              } else if (actualLength > 6 && actualLength % 3 > 0) {
                betweenPostsCtr = Math.floor(actualLength / 3)
              }

              let incrementX = betweenPostsCtr !== 0 ? (endX - startX) / (betweenPostsCtr + 1) : 0
              let incrementY = betweenPostsCtr !== 0 ? (endY - startY) / (betweenPostsCtr + 1) : 0

              let betweenPosts = []
              const index = !oppositeDirectionSpan && canDragSpan && dragSpan ? elements.findIndex(v => v.line === dragHistory.line.line) : elements.length - 1
              const lineNumber = elements[index].line
              const postId = postElements.length

              let image = postImageX
              let imageDirection = ''
              if (ceil(startY) === ceil(endY)) {
                image = postImageY
              } else if ((ceil(startX) < ceil(endX) && ceil(startY) < ceil(endY)) || (ceil(startX) > ceil(endX) && ceil(startY) > ceil(endY))) {
                image = postImageZRL
              } else if ((ceil(startX) > ceil(endX) && ceil(startY) < ceil(endY)) || (ceil(startX) < ceil(endX) && ceil(startY) > ceil(endY))) {
                image = postImageZLR
              }

              for (let i = 0; i < betweenPostsCtr; i++) {
                const postElement = createPostElement(postId, image, startX + incrementX * (i + 1) - (postImageSize / 2), startY + incrementY * (i + 1) - (postImageSize / 2), postImageSize, postImageSize, lineNumber, '', imageDirection)
                betweenPosts.push(postElement)
              }

              const postExist = postElementsCopy.filter(item => ceil(item.x1) === ceil(endX - postImageSize / 2) && ceil(item.y1) === ceil(endY - postImageSize / 2))

              if (postExist.length > 1 || removePostLine !== 'x') {
                const postExistIndex = postElementsCopy.findIndex(item => item.line === postExist[0].line && item.x1 === postExist[0].x1 && postExist[0].y1 === postExist[0].y1)
                if(oppositeDirectionSpan){
                  postElementsCopy[postExistIndex].direction = 'corner'
                }
                
                setCanAdjustSpan(false)
                postElementsCopy = postElementsCopy.filter(item => item.line !== 'tmp')

              } else {
                setCanAdjustSpan(true)
                postElementsCopy = postElementsCopy.map(item =>
                  item.line === 'tmp'
                    ? { ...item, image: image }
                    : item
                )
              }

              postElementsCopy = postElementsCopy.map(item =>
                item.line === 'tmp' || item.line === ''
                  ? { ...item, line: lineNumber }
                  : item
              )
              let allPosts = [...postElementsCopy, ...betweenPosts]

              const linesLine = []
              finalElements.forEach(v => {
                linesLine.push(v.line)
              })
              
              allPosts = allPosts.filter(v => linesLine.includes(v.line))

              allPosts = allPosts.filter((v,i,a) => a.findIndex(t => (t.x1 === v.x1 && t.y1 === v.y1)) === i) 
              
              setPostElements(allPosts)

              let lineCtr = elements.length === 1 || (!oppositeDirectionSpan && canDragSpan && dragSpan) ? betweenPostsCtr + 2 : betweenPostsCtr

              const allPostLine = allPosts.filter(item => item.line === lineNumber)

              if(!oppositeDirectionSpan && canDragSpan && dragSpan && betweenPostsCtr + 1 === allPostLine.length){
                lineCtr = lineCtr - 1
              }

              let linePostsCtr = lineCtr
            
              if(!oppositeDirectionSpan && canDragSpan && dragSpan){
                linePostsCtr = linePostsCtr - removedPosts
              }

              let railCopy = itemList.rails.length ? [...itemList.rails] : []
              railCopy = railCopy.filter(v => linesLine.includes(v.line))
              const foundRail = railCopy.find(item => item.line === lineNumber)
              const totalCornerPosts = cornerPostCounter(allPosts)
              const totalStraightPosts = straightPostCounter(allPosts)
              
              if (!foundRail) {
                railCopy[railCopy.length] = { "count": railCopy.length === 0 ? linePostsCtr - 1 : linePostsCtr + 1, "distance": parseFloat(actualLength * 1000), "line": lineNumber }
  
                setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: railCopy }))

              } else {
  
                const rails = railCopy.map(item =>
                  item.line === lineNumber
                    ? { ...item, count: betweenPostsCtr + 1 === allPostLine.length ? lineCtr : lineCtr - 1, distance: parseFloat(actualLength * 1000) }
                    : item
                )
                setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: rails }))
     
              }
            }
          }
        }
        setConnectedLine(null)
      }
      setRemovedPosts(0)
    }else if(action === 'Gate'){
      if (tempGate.length > 0 && canAddGate) {
        handleMouseDown(event)
      }
    }
  }

  const handleDoubleClick = (event) => {
    const pos = getMousePos(canvas, event, true);

    const clientX = pos.x
    const clientY = pos.y

    const pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    const pointY = inLine(clientY, "y", canvas, cell, postImageSize)

    if ((pointX - canvas.width / cell + postImageSize / 2 < 0) || (canvas.width - (pointX + postImageSize / 2) < canvas.width / cell) || (pointY - canvas.width / cell + postImageSize / 2 < 0)) return

    const line = findLine(clientX, clientY, elements, canvas)
    if (action === 'Post') {

      const inPost = findInPost(clientX, clientY, postElements, elements, postImageSize)

      if (line.length === 0 && inPost.length === 0 && elements.length > 0) {
        setContinueProcess(false)
        return
      }

      setClickedLine(null)
      setContinueProcess(true)
      if (line.length > 0 && inPost.length === 0) {
          setCurrentEvent({ "clientX": clientX, "clientY": clientY })
          setInputLength(true)
          setClickedLine(line[0])
          return
      }

    }

  }

  const handleZoomButtonHover = (e) => {
    const id = e.target.id
    if (id === 'minusButton') {
      e.target.src = minus
    } else if (id === 'plusButton') {
      e.target.src = plus
    }
  }

  const handleZoomButtonMouseOut = (e) => {
    const id = e.target.id
    if (id === 'minusButton') {
      e.target.src = minus_2
    } else if (id === 'plusButton') {
      e.target.src = plus_2
    }
  }

  const handleZoomButtonClick = (e) => {
    setPrevCell(cell)
    setZoomTrigger(true)
    setGateOnQueue(null)
    const id = e.target.id
    if (id === 'minusButton') {
      setCell(cell + 1 > (screenSize === 'md' ? 24 : 16) ? cell : cell + 1)
    } else if (id === 'plusButton') {
      setCell(cell - 1 < initialCell ? cell : cell - 1)
    }
  }

  const handleZoomWheel = (e) => {
    setGateOnQueue(null)
    if (e.deltaY !== 0) {
      setPrevCell(cell)
      setZoomTrigger(true)
      if (e.deltaY < 0) {
        setCell(cell + 1 > initialCell + 8 ? cell : cell + 1)
      } else if (e.deltaY > 0) {
        setCell(cell - 1 < initialCell ? cell : cell - 1)
      }
    }
  }

  const toggleAdjustGateButton = () => {
    setAdjustGate(adjustGate ? false : true)
    setFlipGate(false)
  }

  const toggleFlipGateButton = () => {
    setFlipGate(flipGate ? false : true)
    setAdjustGate(false)
  }

  const toggleModal = () => {
    if (!clickedModal) {

      const srcCanvas = document.getElementById("canvas")

      const destinationCanvas = document.createElement("canvas");
      destinationCanvas.width = srcCanvas.width;
      destinationCanvas.height = srcCanvas.height;

      const destCtx = destinationCanvas.getContext('2d');

      destCtx.fillStyle = "#f2f2f2";
      destCtx.fillRect(0, 0, srcCanvas.width, srcCanvas.height);

      destCtx.drawImage(srcCanvas, 0, 0);

      const dataURL = destinationCanvas.toDataURL();
      setCanvasImage(dataURL)

      formData['canvas'] = dataURL

      const minSpan = 3200
      const items = []
      const formDataitems = []

      itemList['rails'].map((value, index) => {
        const duplicates = itemList['rails'].filter(item => (Math.ceil(item.distance / minSpan) * minSpan) / item.count === (Math.ceil(value.distance / minSpan) * minSpan) / value.count)
        const instance = duplicates.reduce((n, { count }) => n + count, 0)

        if (!items.includes((Math.ceil(value.distance / minSpan) * minSpan) / value.count)) {
          items.push((Math.ceil(value.distance / minSpan) * minSpan) / value.count)
          formDataitems.push(`${instance}x ${Math.round(((Math.ceil(value.distance / minSpan) * minSpan) / value.count + Number.EPSILON) * 100) / 100}mm Top & Bottom Rails`)
        }

        return

      })

      if (itemList['straightPost'] > 0) {
        formDataitems.push(`${itemList['straightPost']}x Straight Post`)
      }

      if (itemList['cornerPost'] > 0) {
        formDataitems.push(`${itemList['cornerPost']}x Corner Post`)
      }

      if (itemList['gates'] > 0) {
        formDataitems.push(`${itemList['gates']}x Gate Kit`)
      }

      formData['items'] = formDataitems

    }
    setClickedModal('checkoutModal')
  }

  const openModal = (modal) => {
    setClickedModal(modal)
  }

  const closeModal = () => {
    setClickedModal(null)
  }

  const sendMail = async () => {
    setSuccessFormSubmit("init")
 
    const submitBtn = document.getElementById('checkoutSubmitBtn')
    submitBtn.disabled = true
    submitBtn.innerHTML = 'Please wait'
    await fetch(mailer_url, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(formData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error === 0) {
            setSuccessFormSubmit("success")
            setTimeout(function () {
              window.location.href = ecomURL
            }, 1500);
          } else {
            setSuccessFormSubmit("error")
            submitBtn.innerHTML = 'Check out'
            submitBtn.disabled = false
          }
        },
        (error) => {
          console.log(error)
          submitBtn.disabled = false
          submitBtn.innerHTML = 'Check out'
        }
      )
  }

  const handleChangeFormInput = (event) => {
    const { name, value } = event.target

    formData[name] = value
  }

  const modalBody = <form>
    <div className="flex flex-col md:flex-col">
      <div className="w-full md:w-8/12">
        <img src={canvasImage} alt="canvas"/>
      </div>
      <div className="w-full md:w-4/12 py-4 px-0 md:px-4 text-sm leading-loose whitespace-pre-line">
        {(formData.items).join(" \n ")}
      </div>
    </div>

    <br />
    <input type="text" className="w-full px-2 py-1 mb-4 text-xs font-medium bg-white border outline-none h-9 focus:border-primary" required="required" placeholder="Name" name="name" onChange={handleChangeFormInput} />
    <input type="email" className="w-full px-2 py-1 text-xs font-medium bg-white border outline-none h-9 focus:border-primary" required="required" placeholder="Email" name="email" onChange={handleChangeFormInput} />
    <br />
    <div className="h-10">
      <span className={`text-xs text-green-400 block py-3 ${successFormSubmit === 'success' ? '' : 'hidden'}`}>An e-mail has been sent. You will now be redirected to cart page.</span>
      <span className={`text-xs text-green-400 block py-3 ${successFormSubmit === 'error' ? '' : 'hidden'}`}>Please provide valid details.</span>
    </div>
    <div className='text-center'>
      <PrimaryButton type="button" id="checkoutSubmitBtn" text="Check out" show={true} buttonEvent={sendMail} />
    </div>
  </form>;

  const handleRemoveGate = () => {
    const gate = gateOnQueue
    let removedGates = 0
    const removeGate = gateElements.filter(item => {
      if (item.x1 === gate.x1 && item.y1 === gate.y1) {
        removedGates++
        return false
      }
      return true
    })
    let removedPostsCtr = 0
    const removePosts = postElements.filter(item => {

      if((((ceil(item.x1) === ceil(gate.x1 - postImageSize / 2) || ceil(item.x1) === ceil(gate.x1 + gate.x2 - postImageSize / 2)) && (ceil(item.y1 + postImageSize * .25) === ceil(gate.y1) || ceil(item.y1 + postImageSize * .25) === ceil(gate.y1 + gateOffset))) || ((ceil(item.y1) === ceil(gate.y1 - postImageSize / 2) || ceil(item.y1) === ceil(gate.y1 + gate.y2 - postImageSize / 2)) && (ceil(item.x1 + postImageSize * .25) === ceil(gate.x1) || ceil(item.x1 + postImageSize * .25) === ceil(gate.x1 + gateOffset)))) && item.postId === 'gate_post'){
        removedPostsCtr++
        return false
      }
      return true
    })

    setItemList(prevState => ({ ...prevState, straightPost: prevState.straightPost - removedPostsCtr, gates: prevState.gates - removedGates }))

    setPostElements(removePosts)
    setGateElements(removeGate)

    if (removeGate.length === 0) {
      setAdjustGate(false)
    }

    setGateOnQueue(null)
  }

  const handleFlipGate = () => {
    const gateX = new Image()
    const gateY = new Image()
    const gateXF = new Image()
    const gateXFA = new Image()
    const gateXFB = new Image()
    const gateYF = new Image()
    const gateYFA = new Image()
    const gateYFB = new Image()

    gateX.src = gate_x
    gateY.src = gate_y
    gateXF.src = gate_x_f
    gateXFA.src = gate_x_fa
    gateXFB.src = gate_x_fb
    gateYF.src = gate_y_f
    gateYFA.src = gate_y_fa
    gateYFB.src = gate_y_fb

    const imagesX = [gateX, gateXF, gateXFA, gateXFB]
    const imagesY = [gateY, gateYF, gateYFA, gateYFB]


    const gate = gateOnQueue
    const gate_copy = gate

    let type = gate.image_type
    gateXF.onload = () => {
      if (type === '') {
        type = 0
      }
      type++

      if (type === 4) {
        type = 0
      }
      gate_copy.image_type = type

      if (gate.x2 < gate.y2) {
        if(type === 2){
          gate_copy.y1 = gate_copy.y1 - gateOffset
        }else if(type === 0){
          gate_copy.y1 = gate_copy.y1 + gateOffset
        }
        gate_copy.image = imagesX[type]
      } else {
        if(type === 2){
          gate_copy.x1 = gate_copy.x1 - gateOffset
        }else if(type === 0){
          gate_copy.x1 = gate_copy.x1 + gateOffset
        }
        gate_copy.image = imagesY[type]
      }

      const gates = gateElements.filter(item => {
        if (item.x1 === gate.x1 && item.y1 === gate.y1) {
          return false
        }
        return true
      })

      gates.push(gate_copy)

      setGateElements(gates)
    }
  }

  return (
    <section className="container px-2 py-8 mx-auto">
      <PageHeading value="Build It Yourself!" />
      <Paragraph value="Select 'Post' then click and drag on the grid to the desired length to create a span!" classes="text-primary font-medium" />
      <Paragraph value="Once your fence is completed hit 'Check out.'" classes="text-primary font-medium" />
      <div className="flex flex-col lg:flex-row lg:w-2/3 lg:items-end lg:justify-between">
        <div className="lg:flex">
          <ActionButton action={action} handleAction={handleAction} />
          <div className="mt-5 h-9 lg:mt-auto">
            <SmallButton text="Clear Grid" classes="mr-1" show={!postElements.length > 0 && !elements.length > 0 && !gateElements.length > 0 && cell === initialCell ? false : true} buttonEvent={clearCanvas} canvas={canvas} />
            <Paragraph value="Top down view // 1m" classes="text-sm md:text-base absolute text-secondary-dark z-20" id="meterView" />
            <div className="absolute flex" id="zoomContainer">
              <button type="button" className="mr-2 outline-none">
                <img src={plus_2} onMouseOver={handleZoomButtonHover} onMouseOut={handleZoomButtonMouseOut} onClick={handleZoomButtonClick} id="plusButton" className="transition" alt="plus button" />
              </button>
              <button type="button" className="outline-none">
                <img src={minus_2} onMouseOver={handleZoomButtonHover} onMouseOut={handleZoomButtonMouseOut} onClick={handleZoomButtonClick} id="minusButton" className="transition" alt="minus button" />
              </button>
            </div>
          </div>
        </div>
        {/* <SmallButton text="How It Works" classes="mt-5 lg:mt-auto" buttonEvent={() => {openModal('howItWorks')}}/> */}
      </div>
      <Paragraph value="Please drag the span that you want to adjust." classes="absolute text-red-300 z-20 mt-3 text-xs" id="cantAdjustText" show={!showCantAdjustText ? false : true} />
      <section className="flex flex-row flex-wrap w-full my-4 space-y-2 lg:flex-nowrap lg:space-x-4 lg:space-y-0 lg:my-8">
        <section id="canvasContainer" className='w-full p-3 bg-gray min-h-64 lg:w-2/3 min-h-20r md:min-h-36r'>
          <Canvas
            id="canvasContainer"
            boardWidth={boardWidth}
            boardHeight={boardHeight}
            handleMouseDown={handleMouseDown}
            handleMouseUp={handleMouseUp}
            handleMouseMove={handleMouseMove}
            handleDoubleClick={handleDoubleClick}
          />
          <SmallTextField classes="absolute z-20" placeholder="Adjust distance eg. 8460mm" show={!inputLength ? false : true} handleChange={handleInput} id="inputField" handleBlur={handleBlur} handleKeyDown={handleKeyDown} value={inputString}/>
          <SmallButton classes="absolute z-20 rounded-none" text="Ok" id="inputFieldBtn" buttonEvent={handleInputFieldBlur} show={!inputLength ? false : true} />
        </section>
        <ItemList classes="lg:w-1/3 w-full pb-28" showCheckout={showCheckOut} itemList={itemList} products={products} checkOutButtonEvent={toggleModal} setUrl={setEcomURL} />
      </section>
      <Modal title="Save Your Plan" body={modalBody} show={clickedModal === 'checkoutModal'} closeModal={closeModal} />
      {/* <Modal title="How It Works" body={modalHowItWorksBody} show={clickedModal === 'howItWorks'} closeModal={closeModal} /> */}
      <GateAction show={gateOnQueue === null ? false : true} gate={gateOnQueue} canvas={canvas} currentEvent={gateCurrentEvent} handleRemoveGate={handleRemoveGate} handleFlipGate={handleFlipGate} />
    </section>
  );
}

export default App;
