import SectionHeading from "../../texts/SectionHeading";
import Paragraph from "../../texts/Paragraph";
import PrimarySubmitButton from "../../buttons/PrimarySubmitButton";
import PrimaryButton from "../../buttons/PrimaryButton";


export default function ItemList(props) {

    const straightPost = props.itemList['straightPost'] > 0 ? <Paragraph value={`${props.itemList['straightPost']}x Straight Post`} classes="text-sm"/> : ''
    const cornerPost = props.itemList['cornerPost'] > 0 ? <Paragraph value={`${props.itemList['cornerPost']}x Corner Post`} classes="text-sm"/> : ''
    const gates = props.itemList['gates'] > 0 ? <Paragraph value={`${props.itemList['gates']}x Gate kit`} classes="text-sm"/> : ''

    const items = []

    const floglass_url = process.env.REACT_APP_WC_URL

    const topBottomRailsProduct = props.products.find(item => item.key === 'top and bottom rails')
    const topBottomRailsId = typeof topBottomRailsProduct !== "undefined" ? topBottomRailsProduct['id'] : ''

    const cornerPostProduct = props.products.find(item => item.key === 'corner post')
    const cornerPostId = typeof cornerPostProduct !== "undefined" ? cornerPostProduct['id'] : ''

    const straightPostProduct = props.products.find(item => item.key === 'straight post')
    const straightPostId = typeof straightPostProduct !== "undefined" ? straightPostProduct['id'] : '' 

    const gateKitProduct = props.products.find(item => item.key === 'gate kit')
    const gateKitId = typeof gateKitProduct !== "undefined" ? gateKitProduct['id'] : ''
    
    let url = floglass_url + (props.itemList['rails'] > 0 || props.itemList['straightPost'] > 0 || props.itemList['cornerPost'] > 0 || props.itemList['gates'] > 0 ? 'cart/?add-to-cart=' : '')

    url = url + (props.itemList['straightPost'] > 0 ? straightPostId + ':' + props.itemList['straightPost'] + ',' : '')
    url = url + (props.itemList['cornerPost'] > 0 ? cornerPostId + ':' + props.itemList['cornerPost'] + ',' : '')
    url = url + (props.itemList['gates'] > 0 ? gateKitId + ':' + props.itemList['gates'] + ',' : '')

    const minSpan = 3200
    let railInstance = 0
    const formDataitems = []
    return <section className={`bg-primary text-white relative ${props.classes}`}>
        <div className="px-4 md:px-8 py-4 border-b">
            <SectionHeading value="Item List" classes="select-none" />            
        </div>
        <div className="px-4 md:px-8 py-4 flex flex-col justify-between min-h-64">
            <div>

                {props.itemList['rails'].length > 0 ? props.itemList['rails'].map((value, index) => {
                    const duplicates = props.itemList['rails'].filter(item => (Math.ceil(item.distance / minSpan) * minSpan) / item.count === (Math.ceil(value.distance / minSpan) * minSpan) / value.count)
                    const instance = duplicates.reduce((n, {count}) => n + count, 0)
                    if(instance > 0){
                        railInstance = instance
                    }
                    if(!items.includes((Math.ceil(value.distance / minSpan) * minSpan) / value.count)){
                        items.push((Math.ceil(value.distance / minSpan) * minSpan) / value.count)
                        formDataitems.push(`${instance}x ${Math.round(((Math.ceil(value.distance / minSpan) * minSpan) / value.count + Number.EPSILON) * 100) / 100}mm Top & Bottom Rails`)
                        return <Paragraph key={index} value={`${instance}x ${Math.round(((Math.ceil(value.distance / minSpan) * minSpan) / value.count + Number.EPSILON) * 100) / 100}mm Top & Bottom Rails`} classes="text-sm"/>
                    }else{
                        return []
                    }
                    
                }) : ''}
                {straightPost}
                {cornerPost}
                {straightPost === '' && cornerPost === '' ? <Paragraph value="Click and drag to add items to the list" classes="text-sm"/> : '' }
                {gates}
            </div>
            {props.setUrl(railInstance > 0 ? url + topBottomRailsId + ':' + railInstance + ',' : url)}
            <PrimaryButton text="Check out" buttonEvent={props.checkOutButtonEvent} show={props.showCheckout && props.products} classes="mb-8 absolute left-1/2 bottom-0 transform -translate-x-1/2" />
        </div>
    </section>;
}