export default function Canvas(props) {
    return <canvas id="canvas" style={{touchAction: "none"}}
            width={props.boardWidth}
            height={props.boardHeight}
            onPointerDown={props.handleMouseDown}
            onPointerUp={props.handleMouseUp}
            onPointerMove={props.handleMouseMove}
            // onTouchStart={props.handleMouseDown}
            // onTouchEnd={props.handleMouseUp}
            // onTouchMove={props.handleMouseMove}
            onDoubleClick={props.handleDoubleClick}
        />;
}